/**
 * @flow
 * @format
 */
import React from 'react';
import { Line } from 'react-chartjs-2';

type Props = {
  datasets: { yAxisID: string, color: string, data: { x: number, y: number }[], title: string }[],
  title: string,
  yAxes: { id: string, stepSize: number }[],
  id: string,
  className?: String,
  xUnit?: string,
  minDate?: Date,
  maxDate?: Date,
};

const MonitoringGraph = (props: Props) => {
  const data = {
    datasets: props.datasets.map((it) => ({
      label: it.title,
      fill: false,
      lineTension: 0.1,
      backgroundColor: `${it.color}5c`,
      borderColor: `${it.color}ff`,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: `${it.color}ff`,
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: `${it.color}ff`,
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: it.data,
      // yAxisID: it.yAxisID,
    })),
  };

  const options = {
    title: { text: props.title },
    maintainAspectRatio: false,
    scales: {
      // yAxes: [
      //   props.yAxes.map((it) => ({
      //     id: it.id,
      //     type: 'linear',
      //     position: it.position,
      //     ticks: {
      //       max: it.max,
      //       min: it.min,
      //       stepSize: it.stepSize,
      //     },
      //   })),
      // ],
      xAxes: [
        {
          title: 'time',
          type: 'time',
          gridLines: {
            lineWidth: 2,
          },
          ticks: {
            min: props.minDate,
            max: props.maxDate,
          },
          time: {
            unit: props.xUnit,
            stepSize: 1,
            unitStepSize: 5,
            displayFormats: {
              millisecond: 'HH:mm:ss',
              second: 'HH:mm:ss',
              minute: 'HH:mm',
              hour: 'DD/MM HH[H]',
              day: 'DD/MM',
              week: 'WW YYYY',
              month: 'MMM DD',
              quarter: 'MMM DD',
              year: 'MMM DD',
            },
          },
        },
      ],
    },
  };

  return (
    <div
      id={props.id}
      className={props.className}
      style={{
        height: 400,
        display: 'inline-block',
        width: '100%',
        padding: 20,
      }}
    >
      <h4 style={{ fontSize: '1em', fontWeight: 700 }}>{props.title}</h4>
      <Line data={data} options={options} />
    </div>
  );
};

MonitoringGraph.defaultProps = {
  className: '',
};

export default MonitoringGraph;
