/**
 * @flow
 * @format
 */
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisibilityAndVendingChanger } from 'src/pages/admin/components';
import Firebase, { withFirebase } from 'src/services/Firebase';
import type { ProductItem } from 'src/data/types/ProductItemType';
import { missionTypes, type MissionType } from 'src/data';
import type { ScenarioVendingInfo } from 'src/data';
import { CatalogTypes } from 'src/services/Firebase/types';
import { InputSelect } from '../../components';

type Props = {
  current: any,
  errors: [],
  decision: ?string,
  removeError: (it: any) => void,
  handleChange: (event: Event) => void,
  sendReview: (info?: ScenarioVendingInfo & { isOfficial: boolean, missionType: MissionType }) => void,
  setLoading: (loading: boolean) => void,
  t: (key: string) => string,
  firebase: Firebase,
};

const ConfirmReview = ({
  errors,
  decision,
  current,
  removeError,
  handleChange,
  sendReview,
  setLoading,
  t,
  firebase,
}: Props) => {
  const [comingSoonNew, setComingSoon] = React.useState<boolean>(false);
  const [externalSellerNew, setExternalSeller] = React.useState<boolean>(false);
  const [visibleNew, setVisible] = React.useState<boolean>(false);
  const [isFreeNew, setIsFree] = React.useState<boolean>(false);
  const [priceNew, setPrice] = React.useState<number>(0);
  const [iapSkuNew, setIapSku] = React.useState<?string>();
  const [expendableNew, setExpendable] = React.useState<boolean>(false);
  const [promoExpirationDateNew, setPromoExpirationDate] = React.useState<?number>();
  const [isOfficialNew, setIsOfficialNew] = React.useState<boolean>(false);
  const [missionTypeNew, setMissionTypeNew] = React.useState<MissionType>(missionTypes.isPrivate);
  const [preconfiguredProductIdNew, setPreconfiguredProductId] = React.useState<?string>();
  const [preconfiguredProductsNew, setPreconfiguredProducts] = React.useState<?(ProductItem[])>();
  const [errorSku, setErrorSku] = React.useState<boolean>(false);

  const retrieveVendingInfoAndProducts = async () => {
    try {
      setLoading(true);
      setErrorSku(false);
      const preconfiguredProducts: Array<ProductItem> = await firebase.getPreconfiguredProducts();
      const vendingInfoSnapshot = await firebase.scenarioVendingInfo(current.scenarioId).once('value');
      // Vending info & visibility
      if (vendingInfoSnapshot.exists()) {
        const {
          comingSoon,
          externalSeller,
          visible,
          expendable,
          isFree,
          iapSku,
          price,
          promoExpirationDate,
        }: ScenarioVendingInfo = vendingInfoSnapshot.val();
        setComingSoon(comingSoon);
        setExternalSeller(externalSeller);
        setVisible(visible);
        setExpendable(expendable);
        setIsFree(isFree);
        setIapSku(iapSku);
        setPrice(price);
        setPromoExpirationDate(promoExpirationDate);
        setPreconfiguredProductId(
          !isFree && expendable ? preconfiguredProducts.find((it) => decodeURI(it.id) === iapSku)?.id : '',
        );
        setPreconfiguredProducts(preconfiguredProducts);
        const headerInfoSnapshot = await firebase.scenarioHeader(current.scenarioId, CatalogTypes.dev).once('value');
        // Mission type
        if (headerInfoSnapshot.exists()) {
          const { missionType }: { missionType: MissionType } = headerInfoSnapshot.val();
          setMissionTypeNew(missionType);
          setIsOfficialNew(missionType === missionTypes.isOfficial);
        }
      }
    } catch (error) {
      console.warn('Cannot load availableSkus', error);
      setErrorSku(true);
    } finally {
      setLoading(false);
    }
  };

  const isValid = () => {
    return isFreeNew || (priceNew !== 0 && !!iapSkuNew);
  };

  const handleVendingVisibilityChange = (event) => {
    const { value, id: fieldName } = event.target;
    if (fieldName === 'isFree' && !value && current.scenarioId && (!iapSkuNew || !iapSkuNew.length)) {
      setIapSku(`com.magnitudelabs.atlantide.${current.scenarioId}`);
    }
    if (fieldName === 'preconfiguredProductId' && preconfiguredProductsNew) {
      const product = value && preconfiguredProductsNew.find((it) => it.id === value);
      if (product) {
        const { id, price } = product;
        setIapSku(decodeURI(id));
        setPrice(price);
      } else {
        setIapSku(`com.magnitudelabs.atlantide.${current.scenarioId}`);
        setPrice(0);
      }
    }
    switch (fieldName) {
      case 'comingSoon':
        setComingSoon(value);
        break;
      case 'externalSeller':
        setExternalSeller(value);
        break;
      case 'visible':
        setVisible(value);
        break;
      case 'expendable':
        setExpendable(value);
        break;
      case 'isFree':
        setIsFree(value);
        break;
      case 'price':
        setPrice(value);
        break;
      case 'promoExpirationDate':
        setPromoExpirationDate(value);
        break;
      case 'preconfiguredProductId':
        setPreconfiguredProductId(value);
        break;
      case 'isOfficial':
        setIsOfficialNew(value);
        break;
      case 'missionType':
        setMissionTypeNew(value);
        break;
      default:
    }
  };

  const sendReviewData = () => {
    if (decision === 'validated') {
      // We also update the vending info
      sendReview({
        comingSoon: comingSoonNew,
        visible: visibleNew,
        isFree: isFreeNew,
        price: priceNew,
        iapSku: iapSkuNew,
        promoExpirationDate: promoExpirationDateNew,
        externalSeller: externalSellerNew,
        expendable: expendableNew,
        isOfficial: isOfficialNew,
        missionType: missionTypeNew,
      });
    } else {
      sendReview();
    }
  };

  React.useEffect(() => {
    retrieveVendingInfoAndProducts();
  }, [current]);

  return (
    <div className="card ml-4 mt-2">
      <div className="card-header">
        <h5 className="card-title">{t('screens.review.reviewSummary')}</h5>
      </div>
      <div className="card-body">
        <p className="card-text">
          <strong>{t('screens.review.problems')}</strong>
          {errors.length}
          <br />
          {errors.map((it, index) => (
            <div
              key={`error_${index}_${it.pathToItem || it.message}`}
              className="border border-danger mb-1 pt-1 pb-1 pl-1 pr-1"
            >
              <strong>{t('screens.review.errorLocation')}</strong>
              {it.pathToItem || 'N.C'}
              <br />
              <strong>{t('screens.review.error')}</strong>
              {it.message}
              <button onClick={() => removeError(it)} className="btn btn-light">
                <FontAwesomeIcon color="red" icon={['fad', 'trash-alt']} />
              </button>
            </div>
          ))}
          <br />
          <br />

          <InputSelect
            fieldName={'decision'}
            value={decision || ''}
            values={['pendingReview', 'validated', 'rejected', 'banned']}
            itemToTitle={(it) => t(`screens.review.statusList.${it}`)}
            itemToId={(it) => it}
            label={t('screens.review.decision')}
            handleChange={handleChange}
          />
        </p>
      </div>

      {decision === 'validated' && (
        <>
          {!errorSku ? (
            <VisibilityAndVendingChanger
              scenarioId={current?.scenarioId}
              comingSoon={comingSoonNew}
              externalSeller={externalSellerNew}
              visible={visibleNew}
              isFree={isFreeNew}
              price={priceNew}
              iapSku={iapSkuNew}
              promoExpirationDate={promoExpirationDateNew}
              expendable={expendableNew}
              missionType={missionTypeNew}
              preconfiguredProductId={preconfiguredProductIdNew}
              preconfiguredProducts={preconfiguredProductsNew}
              handleChange={handleVendingVisibilityChange}
            />
          ) : (
            <button
              className="btn btn-outline-warning ml-2"
              type="button"
              id="button-refresh"
              onClick={retrieveVendingInfoAndProducts}
            >
              {t('screens.review.reload')}
            </button>
          )}
        </>
      )}
      <div className="card-footer">
        <button
          className="btn btn-outline-warning ml-2"
          type="button"
          id="button-deploy"
          onClick={sendReviewData}
          disabled={!decision || (decision === 'validated' && !isValid())}
        >
          {t('screens.review.submit')}
        </button>
      </div>
    </div>
  );
};

export default compose(withFirebase, withTranslation('default'))(ConfirmReview);
