/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import 'bootstrap/dist/css/bootstrap.min.css';
import { UserServiceHelper } from 'src/store/user';
import * as Globals from 'src/constants/globals';
import { ScenarioServiceHelper } from 'src/store/scenario';
import Loader from 'src/pages/components/loader';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withCities } from 'src/pages/cities/WithCities';

import type { City } from 'src/data';
import type { Team } from 'src/data/types/TeamType';
import type { OnboardingOptions } from 'src/data/types/OnboardingOptions';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { generateId } from 'src/data/AtlObject';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import * as OnboardingServiceHelper from 'src/store/scenario/onboarding/OnboardingServiceHelper';
import * as TeamServiceHelper from 'src/store/admin/TeamServiceHelper';
import * as Routes from 'src/constants/routes';
import OnboardingModal from './onboarding/OnboardingModal';
import { InputString, InputSelect, InputBoolean } from '../components/inputs';
import Creating from '../components/loader/Creating';

export type NewScenarioScreenProps = {
  importScenario: ScenarioServiceHelper.importScenarioDataType,
  resetScenario: ScenarioServiceHelper.cleanupType,
  createScenario: ScenarioServiceHelper.createScenarioType,
  dupplicateScenarioAsync: FirebaseHelper.dupplicateScenarioAsyncType,
  getTeams: UserServiceHelper.getUserAdministratedTeamsType,
  getTeam: TeamServiceHelper.getTeamDataType,
  resetOnboardingValues: OnboardingServiceHelper.ResetOnboardingValuesType,
  updateTeamId: OnboardingServiceHelper.UpdateTeamIdType,
  firebase: Firebase,
  engineVersion: number,
  cities: City[],
  locale: string,
  isEditor: boolean,
  validClaims: string[],
  onboarding: OnboardingOptions,
  t: (key: string) => string,
};

const NewScenarioScreen = ({
  importScenario,
  resetScenario,
  createScenario,
  dupplicateScenarioAsync,
  getTeams,
  getTeam,
  resetOnboardingValues,
  updateTeamId,
  firebase,
  engineVersion,
  cities,
  locale,
  isEditor,
  validClaims,
  onboarding,
  t,
}: NewScenarioScreenProps) => {
  let reader: ?FileReader;

  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [headerJson, setHeaderJson] = React.useState();
  const [contentJson, setContentJson] = React.useState();
  const [contentName, setContentName] = React.useState<?string>();
  const [headerName, setHeaderName] = React.useState<?string>();
  const [createNew, setCreateNew] = React.useState<boolean>(false);
  const [imported, setImported] = React.useState<boolean>(false);
  const [createScenarioId, setCreateScenarioId] = React.useState<string>('');
  const [createScenarioIdDuplicate, setCreateScenarioIdDuplicate] = React.useState<string>('');
  const [cityId, setCityId] = React.useState<string>('');
  const [sourceId, setSourceId] = React.useState<string>('');
  const [scenarioId, setScenarioId] = React.useState<?string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingCreation, setLoadingCreation] = React.useState<boolean>(false);
  const [teams, setTeams] = React.useState<Array<Team>>([]);
  const [teamScenarioIdSelect, setTeamScenarioIdSelect] = React.useState<string>('');
  const [teamCheckbox, setTeamCheckbox] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const createScenarioWithId = async (createScenarioId: string, teamId: string, skipOnboarding: boolean) => {
    if (teamId && teamId.length > 0) {
      setLoadingCreation(true);
      if (resetScenario) {
        resetScenario();
      }
      if (createScenario && createScenarioId) {
        try {
          const success = await createScenario(
            createScenarioId,
            teamId,
            firebase,
            engineVersion,
            skipOnboarding,
            onboarding,
          );
          if (success) {
            await resetOnboardingValues();
            setScenarioId(createScenarioId);
            setCreateNew(true);
          }
        } catch (error) {
          console.error(error);
        }
      }
      setLoadingCreation(false);
    }
  };

  /**
   * When we click on the create button, will create a new scenario
   */
  const createNewScenario = (skipOnboarding: boolean) => {
    const idToUse = createScenarioId || `stud_${generateId()}`;
    createScenarioWithId(idToUse, onboarding.teamInputId, skipOnboarding);
  };

  const refreshTeamsAsync = async () => {
    setLoading(true);
    const newTeams: Array<Team> = await getTeams(undefined, firebase);
    setTeams(newTeams);
    if (newTeams.length > 0) {
      setTeamScenarioIdSelect(newTeams[0].uid);
    }
    setLoading(false);
  };

  const dupplicateScenario = async () => {
    setLoading(true);
    try {
      await dupplicateScenarioAsync(createScenarioIdDuplicate, sourceId, cityId);
      setLoading(false);
    } catch (error) {
      console.warn('Cannot dupplicate scenario', error);
      setLoading(false);
    }
  };

  const updateValidity = (contentJson, loading) => {
    setIsValid(!loading && !!contentJson);
  };

  const handleChange = (event) => {
    const { value, id: fieldName } = event.target;
    switch (fieldName) {
      case 'createScenarioId':
        setCreateScenarioId(value);
        break;
      case 'createScenarioIdDuplicate':
        setCreateScenarioIdDuplicate(value);
        break;
      case 'sourceId':
        setSourceId(value);
        break;
      case 'cityId':
        setCityId(value);
        break;
      case 'teamScenarioId':
        updateTeamId(value);
        break;
      case 'teamSelect':
        setTeamScenarioIdSelect(value);
        break;
      case 'teamCheckbox':
        setTeamCheckbox(value);
        break;
      default:
    }
  };

  const handleScenarioLoaded = () => {
    if (reader) {
      // $FlowFixMe: Result is string when using readAsText
      const content: string = reader.result;
      const json = content && JSON.parse(content);
      setContentJson(json);
      updateValidity(json, false);
    }
  };

  const loadScenarioFile = (event) => {
    updateValidity(undefined, true);
    const { files } = event.target;
    const first = files[0];
    setContentName(first.name);
    if (first) {
      reader = new FileReader();
      reader.onloadend = handleScenarioLoaded;
      reader.readAsText(first);
    }
  };

  const handleHeaderLoaded = () => {
    if (reader) {
      // $FlowFixMe: Result is string when using readAsText
      const content: string = reader.result;
      const json = JSON.parse(content);
      setHeaderJson(json);
      updateValidity(contentJson, false);
    }
  };

  const loadHeaderFile = (event) => {
    updateValidity(undefined, true);
    const { files } = event.target;
    const first = files[0];
    setHeaderName(first.name);
    if (first) {
      reader = new FileReader();
      reader.onloadend = handleHeaderLoaded;
      reader.readAsText(first);
    }
  };

  const startImport = async () => {
    setLoading(true);
    if (resetScenario) {
      resetScenario();
    }
    let scenarioId: string = 'unnamed';
    if (importScenario) {
      try {
        scenarioId = await importScenario(contentJson, headerJson, false, engineVersion);
        setImported(true);
        setScenarioId(scenarioId);
        setLoading(false);
      } catch (error) {
        setImported(false);
        setLoading(false);
        console.log('Could not import scenario', error);
      }
    }
  };

  /**
   * We check if the selected team exists
   */
  const createNewScenarioAdmin = async () => {
    if (teams.map((team) => team.uid).includes(onboarding.teamInputId)) {
      setOpenModal(true);
    } else {
      setLoading(true);
      const teamAsked = await getTeam(onboarding.teamInputId, 'id', firebase);
      if (teamAsked.length) {
        setOpenModal(true);
      }
      setLoading(false);
    }
  };

  /**
   * Retrieves the teams of a user
   */
  React.useEffect(() => {
    refreshTeamsAsync();
  }, []);

  /**
   * Updates the team id input when needed
   */
  React.useEffect(() => {
    if (!teamCheckbox) {
      updateTeamId(teamScenarioIdSelect);
    }
  }, [teamScenarioIdSelect, teamCheckbox]);

  /**
   * Opens the modal for the lambda user, the admin stays on the page
   */
  React.useEffect(() => {
    if (validClaims.length && !isEditor) {
      setOpenModal(true);
    }
  }, [validClaims]);

  if (scenarioId && (createNew || imported)) {
    return <Redirect to={Routes.SCENARIO_EDITION.replace(':scenarioId', scenarioId)} />;
  }
  const isCreateIdValid =
    createScenarioId &&
    createScenarioId.length > 2 &&
    createScenarioId.match(Globals.idRegex) &&
    onboarding.teamInputId.length > 0;
  const isDupplicateValid =
    createScenarioIdDuplicate.length > 2 &&
    createScenarioIdDuplicate.match(Globals.idRegex) &&
    sourceId.length > 2 &&
    cityId.length > 0;

  return (
    <div className="pageContainer">
      {isEditor && (
        <div className="container-fluid component-controller">
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t('screens.newScenario.create.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              <InputString
                fieldName="createScenarioId"
                value={createScenarioId}
                label={t('screens.newScenario.create.idLabel')}
                handleChange={handleChange}
                help={t('screens.newScenario.create.idHelp')}
              />
              {teams.length > 0 && (
                <InputSelect
                  className="mt-4"
                  fieldName="teamSelect"
                  value={teamScenarioIdSelect}
                  values={teams}
                  itemToId={(team) => team.uid}
                  itemToTitle={(team) => `${team.name} | ${team.uid}`}
                  label={t('screens.newScenario.create.teamSelectLabel')}
                  disabled={teamCheckbox}
                  handleChange={handleChange}
                  disableEmptyOption={true}
                />
              )}
              <InputString
                fieldName="teamScenarioId"
                value={onboarding.teamInputId}
                label={t('screens.newScenario.create.teamIdLabel')}
                handleChange={handleChange}
                help={t('screens.newScenario.create.teamIdHelp')}
                disabled={!teamCheckbox}
              />
              <InputBoolean
                fieldName="teamCheckbox"
                value={teamCheckbox}
                handleChange={handleChange}
                help={t('screens.newScenario.create.teamCheckboxHelp')}
                label={t('screens.newScenario.create.teamCheckboxLabel')}
              />
              <button
                className={`btn mb-3 ${isCreateIdValid ? 'btn-warning' : 'btn-outline-secondary'}`}
                type="button"
                id="button-addon2"
                onClick={createNewScenarioAdmin}
                disabled={!isCreateIdValid}
              >
                {t('general.create')}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t('screens.newScenario.duplicate.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              <InputString
                fieldName="createScenarioIdDuplicate"
                value={createScenarioIdDuplicate}
                label={t('screens.newScenario.create.idLabel')}
                handleChange={handleChange}
                help={t('screens.newScenario.create.idHelp')}
              />
              <InputString
                fieldName="sourceId"
                value={sourceId}
                label={t('screens.newScenario.create.sourceIdLabel')}
                handleChange={handleChange}
                help={t('screens.newScenario.create.sourceIdHelp')}
              />
              <InputSelect
                fieldName="cityId"
                value={cityId}
                values={cities}
                itemToId={(it) => it.id}
                itemToTitle={(it) => it.name.valueForLocale(locale)}
                label={t('screens.newScenario.duplicate.cityLabel')}
                handleChange={handleChange}
              />
              <button
                className={`btn mb-3 ${isDupplicateValid ? 'btn-warning' : 'btn-outline-secondary'}`}
                type="button"
                id="button-addon3"
                onClick={dupplicateScenario}
                disabled={!isDupplicateValid}
              >
                {t('general.dupplicate')}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t('screens.newScenario.import.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              <div className="custom-file mb-3">
                <input
                  type="file"
                  className="custom-file-input mb-3"
                  accept=".json"
                  id="importScenario"
                  onChange={loadScenarioFile}
                />
                <label className="custom-file-label" htmlFor="importScenario" aria-describedby="inputGroupFileAddon02">
                  {contentName || t('screens.newScenario.import.importContentJson')}
                </label>
              </div>
              <div className="custom-file mb-3">
                <fieldset>
                  <input
                    type="file"
                    className="custom-file-input mb-3"
                    accept=".json"
                    id="importHeader"
                    onChange={loadHeaderFile}
                  />
                  <label className="custom-file-label" htmlFor="importHeader" aria-describedby="inputGroupFileAddon02">
                    {headerName || t('screens.newScenario.import.importHeader')}
                  </label>
                </fieldset>
              </div>
              <button
                className="btn btn-outline-secondary mb-3"
                type="button"
                id="button-addon2"
                onClick={startImport}
                disabled={!isValid}
              >
                {t('general.import')}
              </button>
            </div>
          </div>
        </div>
      )}
      <OnboardingModal
        openModal={openModal}
        loading={loading}
        loadingCreation={loadingCreation}
        teams={teams}
        isEditor={isEditor}
        createNewScenario={createNewScenario}
      />
      {loading && <Loader />}
      {loadingCreation && <Creating />}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  engineVersion: state.configuration.engineVersion,
  locale: state.preferences.editionLocale,
  cities: Object.values(state.configuration.availableCities),
  isEditor:
    ownProps.validClaims.includes(Claims.ConfirmedEditor) ||
    ownProps.validClaims.includes(Claims.Admin) ||
    ownProps.validClaims.includes(Claims.Moderator),
  onboarding: state.scenario.onboarding,
});

const mapDispatchToProps = {
  resetScenario: ScenarioServiceHelper.cleanup,
  createScenario: ScenarioServiceHelper.createScenario,
  importScenario: ScenarioServiceHelper.importScenarioData,
  dupplicateScenarioAsync: FirebaseHelper.dupplicateScenarioAsync,
  getTeams: UserServiceHelper.getUserEditingTeams,
  getTeam: TeamServiceHelper.getTeamData,
  resetOnboardingValues: OnboardingServiceHelper.resetOnboardingValues,
  updateTeamId: OnboardingServiceHelper.updateTeamId,
};

export default compose(
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.Moderator, Claims.ConfirmedEditor]),
  withCities,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(NewScenarioScreen);
