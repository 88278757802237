/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import { Link } from 'react-router-dom';

import './style.css';
import 'font-awesome/css/font-awesome.min.css';

type Props = {
  title: string,
  url: string,
  imgClass: string,
  id: string,
  img?: any,
};

class DashboardButton extends React.Component<Props> {
  render() {
    const { title, url, img, id } = this.props;
    return (
      <div className="btn col-12 col-md-3 dashboard-option">
        <Link to={url}>
          <img src={img} className="logo" alt="logo" />
          <h4>{title}</h4>
          <div className={'btn btn-primary dashboard-button hidden'} id={id}>
            OPEN
          </div>
        </Link>
      </div>
    );
  }
}

export default DashboardButton;
