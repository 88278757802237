/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputNumberProps {
  style?: any;
  fieldName: string;
  value: number | string;
  label: string;
  help?: ?string;
  disabled: boolean;
  handleChange: (any) => any;
  hidden: boolean;
  separatorBefore?: boolean;
  inlineTitle?: boolean;
  onFocus?: () => any;
  prependStyle?: ?any;
  inputStyle?: ?any;
  suffix?: ?string;
}

export default class InputNumber extends React.Component<InputNumberProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    separatorBefore: false,
    prependStyle: {},
    inputStyle: {},
  };

  render() {
    const {
      fieldName,
      value,
      label,
      help,
      disabled,
      handleChange,
      hidden,
      separatorBefore,
      inlineTitle,
      onFocus,
      style,
      suffix,
      prependStyle,
      inputStyle,
    } = this.props;
    const divClass = inlineTitle ? 'input-group' : 'form-group';
    const labelClass = inlineTitle ? 'input-group-prepend mr-2 text-capitalize' : 'strong text-capitalize';
    const suffixStyle = { ...prependStyle, borderTopRightRadius: '5px', borderBottomRightRadius: '5px' };

    return (
      <div className={divClass} key={fieldName} hidden={hidden} style={style}>
        {separatorBefore && <hr />}
        <label className={labelClass} htmlFor={fieldName}>
          {label}
        </label>
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted mb-2">
            {help}
          </small>
        )}
        <div className="input-group" style={{ height: 'auto' }}>
          <input
            type="number"
            className="form-control"
            id={fieldName}
            onChange={handleChange}
            value={value}
            disabled={disabled}
            aria-describedby={`${fieldName}Help`}
            placeholder={'...'}
            onFocus={onFocus}
            style={{ ...inputStyle, width: 'auto', height: 'auto' }}
          />
          {suffix && (
            <div className="input-group-prepend h-100">
              <span className="input-group-text h-100" id="basic-addon1" style={suffixStyle}>
                {suffix}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
