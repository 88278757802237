/**
 * @flow
 *
 * @format
 */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

import { Loader, InputSelect, InputDate, withConfirm } from 'src/pages/components';
import { compose } from 'redux';
import { LocalizedString, City } from 'src/data';
import type { ScenarioVendingInfo } from 'src/data';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { withCities } from 'src/pages/cities/WithCities';
import { withTranslation } from 'react-i18next';
import { Claims } from 'src/constants/roles';

import MonitoringLineGraph from './MonitoringLineGraph';
import DoughnutGraph from './DoughnutGraph';

type Props = {
  firebase: Firebase,
  getScenarioSalesStatistics: FirebaseHelper.getScenarioSalesStatisticsType,
  getScenarioSuccessStatistics: FirebaseHelper.getScenarioSuccessStatisticsType,
  locale: string,
  t: (key: string) => string,
  cities: City[],
};

type ScenarioInfo = {
  id: string,
  cityId: string,
  name: LocalizedString,
  subtitle: LocalizedString,
  vendingInfo: ScenarioVendingInfo,
  lastVersion: string,
  currentVersion?: string,
  alert: any,
};

const indexToColor = (index: number) => {
  switch (index) {
    case 0:
      return '#011A48';
    case 1:
      return '#006092';
    case 2:
      return '#00AEBF';
    case 3:
      return '#6cfbce';
    case 4:
      return '#CFA616';
    default:
      return '#000000';
  }
};

const periods = [
  {
    id: 'month',
    title: 'Mois',
  },
  {
    id: 'week',
    title: 'Semaine',
  },
];

const StatisticsTab = (props: Props) => {
  const [currentScenarios, setCurrentScenarios] = useState({});
  const [matchingScenarios, setMatchingScenarios] = useState({});
  const [scenarioId, setScenarioId] = useState(undefined);
  const [period, setPeriod] = useState(periods[0].id);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [cityId, setCityId] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [saleDataset, setSaleDataset] = useState([]);
  const [saleGlobalDataset, setSaleGlobalDataset] = useState([]);
  const [saleGlobalLabels, setSaleGlobalLabels] = useState([]);
  // const [prevPeriodSaleDataset, setPrevPeriodSaleDataset] = useState([]);
  // const [periodSaleDataset, setPeriodSaleDataset] = useState([]);
  const [successDataset, setSuccessDataset] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [periodTotalSales, setPeriodTotalSales] = useState(0);
  const [prevPeriodTotalSales, setPrevPeriodTotalSales] = useState(0);
  // const [successGlobalDataset, setSuccessGlobalDataset] = useState([]);
  // const [successGlobalLabels, setSuccessGlobalLabels] = useState([]);
  const [totalPlayed, setTotalPlayed] = useState(0);
  const [periodTotalPlayed, setPeriodTotalPlayed] = useState(0);
  const [prevPeriodTotalPlayed, setPrevPeriodTotalPlayed] = useState(0);
  // const [prevPeriodSuccessDataset, setPrevPeriodSuccessDataset] = useState([]);
  // const [periodSuccessDataset, setPeriodSuccessDataset] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    reloadScenariosAsync();
    const set = [
      {
        color: indexToColor(0),
        title: 'Super titre',
        data: [],
      },
    ];
    setSaleDataset(set);
  }, []);

  const refreshStatsAsync = async () => {
    const { t } = props;
    if (scenarioId) {
      setLoading(true);
      const res = await props.getScenarioSalesStatistics(scenarioId, startDate, endDate, period);
      if (!res.error || res.length > 0) {
        const set = [
          {
            color: indexToColor(0),
            title: 'Ventes globales',
            data: res.values.map((it) => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.count,
            })),
          },
          {
            color: indexToColor(1),
            title: 'Ventes plein tarif',
            data: res.values.map((it) => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.default_count,
            })),
          },
          {
            color: indexToColor(2),
            title: 'Ventes welcomes',
            data: res.values.map((it) => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.welcome_count,
            })),
          },
          {
            color: indexToColor(3),
            title: 'Ventes launch',
            data: res.values.map((it) => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.launch_count,
            })),
          },
        ];
        setSaleDataset(set);
        setSaleGlobalDataset([res.sum.default_sum, res.sum.welcome_sum, res.sum.launch_sum]);
        // setPeriodSaleDataset([res.period.default_sum, res.period.welcome_sum, res.period.launch_sum]);
        setPeriodTotalSales(res.period.total);
        setPrevPeriodTotalSales(res.prevPeriod.total || 0);
        // setPrevPeriodSaleDataset([
        //   res.prevPeriod.default_sum || 0,
        //   res.prevPeriod.welcome_sum || 0,
        //   res.prevPeriod.launch_sum || 0,
        // ]);
        setTotalSales(res.sum.total);
        setSaleGlobalLabels(['Plein tarif', 'Welcome', 'Launch']);
        setTotalSales(res.sum.total);
      }

      const resSuccess = await props.getScenarioSuccessStatistics(scenarioId, startDate, endDate, period);
      if (!resSuccess.error) {
        const set = [
          {
            color: indexToColor(0),
            title: t('screens.stats.playedGamesGraphTitle'),
            data: resSuccess.values.map((it) => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.launch_count,
            })),
          },
          {
            color: indexToColor(1),
            title: t('screens.stats.winedGamesGraphTitle'),
            data: resSuccess.values.map((it) => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.success_count,
            })),
          },
          {
            color: indexToColor(2),
            title: t('screens.stats.finishedGamesGraphTitle'),
            data: resSuccess.values.map((it) => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.end_count,
            })),
          },
          {
            color: indexToColor(3),
            title: t('screens.stats.100percentGamesGraphTitle'),
            data: resSuccess.values.map((it) => ({
              x: moment(it.date, 'YYYY-MM-DD')
                .toDate()
                .getTime(),
              y: it.story_completed_count,
            })),
          },
        ];
        setSuccessDataset(set);

        setPeriodTotalPlayed(resSuccess.period.launch_sum);
        setPrevPeriodTotalPlayed(resSuccess.prevPeriod.launch_sum);
        setTotalPlayed(resSuccess.sum.launch_sum);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const minDateMoment = moment().subtract(12, period);
    const maxDateMoment = moment();
    setStartDate(minDateMoment.toISOString());
    setEndDate(maxDateMoment.toISOString());
  }, [period]);
  useEffect(() => {
    refreshStatsAsync();
  }, [scenarioId, startDate, endDate]);

  const handleCityChange = (event) => {
    const { value } = event.target;
    // $FlowFixMe Boolean is only used for bool fields
    setCityId(value);
  };
  const handlePeriod = (event) => {
    const { value } = event.target;
    // $FlowFixMe Boolean is only used for bool fields
    setPeriod(value);
  };
  const handleStart = (event) => {
    const { value } = event.target;
    setStartDate(value);
  };

  const handleEnd = (event) => {
    const { value } = event.target;
    setEndDate(value);
  };
  const selectScenario = (scenario: { id: string }) => {
    const { id } = scenario;
    setScenarioId(id);
  };

  const reloadScenariosAsync = async () => {
    try {
      setLoading(true);
      let isAdmin;
      const user = props.firebase.auth.currentUser;
      await props.firebase.hasClaims(user, [Claims.Admin]).then((validClaims) => {
        validClaims.length === 0 ? (isAdmin = false) : (isAdmin = true);
      });
      if (isAdmin !== true) {
        let authorizedScenarios;
        await props.firebase.listAuthorizedScenarios(props.firebase.userId).then((scenarios) => {
          authorizedScenarios = scenarios;
        });
        setCurrentScenarios(authorizedScenarios);
      } else {
        const { currentScenarios } = await props.firebase.getScenariosToDeployAsync();
        setCurrentScenarios(currentScenarios);
      }
    } catch (error) {
      console.warn('Cannot load scenarios', error);
    }
    setLoading(false);
  };

  const updateMatchingScenarios = () => {
    const matchingScenarios = {};
    if (currentScenarios) {
      Object.keys(currentScenarios).forEach((it: string) => {
        if (cityId) {
          // $FlowFixMe indexer
          if (currentScenarios[it].cityId === cityId) {
            matchingScenarios[it] = currentScenarios[it];
          }
        } else {
          // $FlowFixMe indexer
          matchingScenarios[it] = currentScenarios[it];
        }
      });
    }
    setMatchingScenarios(matchingScenarios);
  };

  useEffect(() => {
    updateMatchingScenarios();
  }, [cityId, currentScenarios]);
  useEffect(() => {
    updateMatchingScenarios();
  }, []);

  const renderListButton = (element: ScenarioInfo) => {
    const { locale } = props;
    const cityId = element.cityId || '??';
    const scenarioIdVal = scenarioId || '??';
    let buttonClass = 'list-group-item list-group-item mb-3 list-group-item-action align-items-start';
    if (scenarioIdVal !== '??' && element.id === scenarioIdVal) {
      buttonClass += ' active';
    }

    const { id, name, subtitle } = element;

    return (
      <div className="" key={id}>
        <button id={id} className={buttonClass} onClick={() => selectScenario(element)}>
          <div className="d-flex justify-content-between">
            <h5 className="mb-1">{name.valueForLocale(locale)}</h5>
            <small className="text-muted">{`${cityId}/${id}`}</small>
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-1">{subtitle.valueForLocale(locale)}</p>
          </div>
        </button>
      </div>
    );
  };

  const { locale, t } = props;
  return (
    <div id="statistics">
      <div className="filter mb-3">
        <div className="row ml-0 mr-0">
          <div>
            <InputSelect
              className="col-4"
              fieldName={'period'}
              value={period}
              values={periods}
              itemToId={(it) => it.id}
              itemToTitle={(it) => it.title}
              label={t('screens.stats.period')}
              handleChange={handlePeriod}
            />
          </div>
          <div className="ml-5">
            <InputDate
              // style={{ minWidth: 300 }}
              fieldName="startDate"
              label={t('screens.stats.startDate')}
              value={startDate}
              handleChange={handleStart}
              displayBr
            />
          </div>
          <div className="ml-2">
            <InputDate
              fieldName="endDate"
              // style={{ marginLeft: 40 }}
              label={t('screens.stats.endDate')}
              value={endDate}
              handleChange={handleEnd}
              displayBr
            />
          </div>
        </div>
      </div>
      <div className="card bg-light screenBlock fill" style={{ overflow: 'scroll' }}>
        <div className="card-body d-flex p-2 pl-4">
          <div className="row fill" style={{ height: '100%', overflow: 'scroll', paddingBottom: 100 }}>
            <div className="list-group fill col-4 pb-2">
              <InputSelect
                fieldName={'cityId'}
                value={cityId}
                values={props.cities}
                itemToId={(it) => it.id}
                itemToTitle={(it) => it.name.valueForLocale(locale)}
                label={t('screens.stats.city')}
                handleChange={handleCityChange}
              />
              <div style={{ overflow: 'scroll', maxHeight: '60vh' }}>
                {matchingScenarios &&
                  /* $FlowFixMe: Object.values */
                  Object.values(matchingScenarios).map((element: ScenarioInfo) => renderListButton(element))}
              </div>
            </div>
            <div className="accordion col-8" id="statsAccordion">
              <div className="card">
                <div className="card-header bg-light" id="headingTotalSales">
                  <h2 className="mb-0">
                    <div
                      className="text-left stat-accordion-toggle"
                      data-toggle="collapse"
                      data-target="#collapseTotalSales"
                      aria-expanded="true"
                      aria-controls="collapseTotalSales"
                    >
                      <div className="big-stat">{t('screens.stats.totalSales')}</div>
                    </div>
                  </h2>
                </div>

                <div
                  id="collapseTotalSales"
                  className="collapse show"
                  aria-labelledby="headingTotalSales"
                  data-parent="#statsAccordion"
                >
                  <div className="card-body row">
                    <div className="col-6">
                      <div className="medium-stat">{`${totalSales > 0 ? totalSales : '0'} ${t(
                        'screens.stats.salesCount',
                      )}`}</div>
                      <div className="medium-stat">{`${periodTotalSales > 0 ? periodTotalSales : '0'} ${t(
                        'screens.stats.periodSales',
                      )}`}</div>
                      <div className="medium-stat">{`${prevPeriodTotalSales > 0 ? prevPeriodTotalSales : '0'} ${t(
                        'screens.stats.previousPeriodSales',
                      )}`}</div>
                    </div>
                    <div className="col-6">
                      <DoughnutGraph
                        title={t('screens.stats.salesPerPricing')}
                        data={saleGlobalDataset}
                        labels={saleGlobalLabels}
                      />
                    </div>
                    <div className="col-12 col-xxl-6">
                      <MonitoringLineGraph
                        xUnit={period}
                        minDate={startDate}
                        maxDate={endDate}
                        id="salesGraph"
                        title={t('screens.stats.periodSales')}
                        datasets={saleDataset}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header bg-light" id="headingTotalPlayed">
                  <h2 className="mb-0">
                    <div
                      className="text-left stat-accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTotalPlayed"
                      aria-expanded="false"
                      aria-controls="collapseTotalPlayed"
                    >
                      <div className="big-stat">{t('screens.stats.playedTitle')}</div>
                    </div>
                  </h2>
                </div>
                <div
                  id="collapseTotalPlayed"
                  className="collapse"
                  aria-labelledby="headingTotalPlayed"
                  data-parent="#statsAccordion"
                >
                  <div className="card-body">
                    <div className="col-6">
                      <div className="medium-stat">{`${totalPlayed > 0 ? totalPlayed : '0'} ${t(
                        'screens.stats.playedTotal',
                      )}`}</div>
                      <div className="medium-stat">{`${periodTotalPlayed > 0 ? periodTotalPlayed : '0'} ${t(
                        'screens.stats.playedOverPeriod',
                      )}`}</div>
                      <div className="medium-stat">{`${prevPeriodTotalPlayed > 0 ? prevPeriodTotalPlayed : '0'} ${t(
                        'screens.stats.playedOverPreviousPeriod',
                      )}`}</div>
                    </div>
                    <div className="col-12 col-xxl-6">
                      <MonitoringLineGraph
                        xUnit={period}
                        minDate={startDate}
                        maxDate={endDate}
                        id="successGraph"
                        title={t('screens.stats.successsRateGraph')}
                        datasets={successDataset}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

const sortCities = (a: City, b: City) => a.id.localeCompare(b.id, 'fr');

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
  cities: Object.values(state.configuration.availableCities).sort(sortCities),
});

const mapDispatchToProps = {
  getScenarioSalesStatistics: FirebaseHelper.getScenarioSalesStatistics,
  getScenarioSuccessStatistics: FirebaseHelper.getScenarioSuccessStatistics,
};

export default compose(
  withCities,
  withConfirm,
  withFirebase,
  withTranslation('default'),
  connect(mapStateToProps, mapDispatchToProps),
)(StatisticsTab);
