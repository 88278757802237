/**
 * @flow
 *
 * @format
 */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';

import { InputString, Loader, withConfirm } from 'src/pages/components';
import type { withConfirmProps } from 'src/pages/components';
import { LocalizedString } from 'src/data';
import type { ObjectMap, ScenarioVendingInfo } from 'src/data';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase, FirebaseSingleton } from 'src/services/Firebase';
import { ScenarioServiceHelper } from 'src/store/scenario';
import ReleaseListButton from './components/ReleaseListButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';

type Props = withConfirmProps & {
  firebase: Firebase,
  submitScenario: ScenarioServiceHelper.submitScenarioAsyncType,
  t: (key: string) => string,
};

type Submission = {
  submissionId: string,
  scenarioId: string,
  teamId: string,
  version: string,
  submittedBy: string,
  submissionDate: Date,
  reviewedBy?: string,
  reviewedOn?: Date,
  status: SubmissionStatusType,
  feed?: SubmissionFeedContent[],
};

type ReleaseInfos = {
  scenarioId: string,
  title: ObjectMap<string, string>,
  engineVersion: string,
  submittableVersion: string,
  team: string,
  isLive: boolean,
  canSubmit: boolean,
  devReleaseDate: Date,
  submissionsHistory: {
    last?: Submission,
    pending?: Submission,
    banned?: Submission,
  },
};

const defaultReleaseInfo = {
  scenarioId: undefined,
  title: { fr: '', en: '' },
  engineVersion: 1,
  submittableVersion: 'v0',
  team: '',
  isLive: false,
  canSubmit: false,
  devReleaseDate: new Date(),
  submissionsHistory: {},
};

const MyRealeases = (props: Props) => {
  const [teamScenarios, setTeamScenarios] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [scenarioReleaseInfo, setScenarioReleaseInfo] = useState({ ...defaultReleaseInfo });
  const [submissionMessage, setSubmissionMessage] = useState('');

  const selectRelease = (release) => {
    setScenarioReleaseInfo(release);
  };

  const { t } = props;
  const submitRelease = async (isForced?: boolean = false) => {
    setLoading(true);
    try {
      const { hasWarn, submission } = await props.submitScenario(
        scenarioReleaseInfo.team,
        scenarioReleaseInfo.scenarioId,
        scenarioReleaseInfo.submittableVersion,
        submissionMessage,
        isForced,
        t,
      );
      if (hasWarn) {
        props.alert(t('screens.myReleases.confirmReleaseWithWarns'), [
          { text: t('general.confirm'), onPress: () => submitRelease(true) },
          { text: t('general.cancel'), onPress: () => {} },
        ]);
      }
      if (submission) {
        // Success
        await props.addNotif(NotificationTypes.SUCCESS, 'S_SUBMISSION_SENT');
        // eslint-disable-next-line no-use-before-define
        await updateReleaseListAsync();
      }
    } catch (error) {
      await props.addNotif(
        NotificationTypes.ERROR,
        error.message.startsWith('E_') ? error.message : 'E_SUBMISSION_FAILED',
      );
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSubmissionMessage(value);
  };

  const lastSubmission = scenarioReleaseInfo?.submissionsHistory?.last;
  const pendingSubmission = scenarioReleaseInfo?.submissionsHistory?.pending;

  const updateReleaseListAsync = async () => {
    setLoading(true);
    try {
      const { data } = await FirebaseSingleton.getUserTeamReleasesState();
      setTeamScenarios(data?.releasesData || []);
    } catch (error) {
      console.error(error);
    }
    setSubmissionMessage('');
    setLoading(false);
  };

  const cancelPendingSubmission = async () => {
    setLoading(true);
    try {
      await props.cancelPendingSubmission(pendingSubmission.scenarioId, pendingSubmission.submissionId);
      await props.addNotif(NotificationTypes.SUCCESS, 'S_SUBMISSION_CANCELLED');
      updateReleaseListAsync();
    } catch (error) {
      await props.addNotif(NotificationTypes.ERROR, error.message.startsWith('E_') ? error.message : 'E_UNKNOWN');
    }
    setLoading(false);
  };

  useEffect(() => {
    updateReleaseListAsync();
  }, []);

  const liveSubmission = scenarioReleaseInfo?.submissionsHistory?.live;
  const canSubmit = !pendingSubmission || ['validated', 'canceled', 'rejected'].includes(pendingSubmission.status);
  return (
    <div className="pageContainer noScroll" style={{ maxHeight: 'calc(100vh - 80px)' }}>
      <div className="row mr-0 noScroll" style={{ height: 'calc(100vh - 100px)' }}>
        <div className="col-4 fill noScroll" id="leftColumn" style={{ height: '100%' }}>
          <div
            className="container-fluid  background-primary "
            id="graphContainer"
            style={{ overflowY: 'scroll', height: '100%' }}
          >
            <div className="list-group pb-2 pl-2 pr-2 pt-2">
              {teamScenarios.map((element: ReleaseInfos) => (
                <ReleaseListButton
                  key={element.scenarioId}
                  info={element}
                  onClick={selectRelease}
                  isActive={element.scenarioId === scenarioReleaseInfo.scenarioId}
                />
              ))}
              {!scenarioReleaseInfo.length && <h6>{t('screens.myReleases.noScenario')}</h6>}
            </div>{' '}
          </div>
        </div>
        <div className="col-8 d-flex pb-4 pl-2 pt-2 noScroll" style={{ height: '100%' }}>
          {scenarioReleaseInfo.scenarioId ? (
            <div style={{ height: 'auto', overflowY: 'auto' }}>
              <div>
                <h4>
                  {scenarioReleaseInfo.scenarioId} - {scenarioReleaseInfo.name[props.locale]}
                </h4>
                {lastSubmission && (
                  <div className="card mb-2">
                    <div className="card-header bg-transparent">
                      <h5 className="card-title">
                        {t('screens.myReleases.lastSubmission')}
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={['fad', t(`screens.myReleases.statusIcon.${lastSubmission.status}`)]}
                          color={t(`screens.myReleases.statusColor.${lastSubmission.status}`)}
                        />
                      </h5>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <strong>{t('screens.myReleases.releaseDate')}</strong>
                        {moment(lastSubmission.releaseDate).format('DD/MM/YYYY HH:mm')}
                        <br />
                        <strong>{t('screens.myReleases.submissionDate')}</strong>
                        {moment(lastSubmission.submissionDate).format('DD/MM/YYYY HH:mm')}
                        <br />
                        <strong>{t('screens.myReleases.reviewedOn')}</strong>
                        {moment(lastSubmission.reviewedOn).format('DD/MM/YYYY HH:mm')}
                        <br />
                        <strong>{t('screens.myReleases.versionNumber')}</strong>
                        {lastSubmission.version}
                        <br />
                        <strong>{t('screens.myReleases.status')}</strong>
                        {t(`screens.myReleases.statusList.${lastSubmission.status}`)}
                        <br />
                        {!!lastSubmission.feed?.length && (
                          <React.Fragment>
                            <strong>{t('screens.myReleases.feed')}</strong>
                            {lastSubmission.feed.map((notif, index) => (
                              <div
                                className="alert alert-danger mb-2"
                                role="alert"
                                key={`${lastSubmission.submissionId}_feed_${index}`}
                              >
                                <div>
                                  <strong>{notif.message}</strong>
                                  {notif.pathToItem && (
                                    <React.Fragment>
                                      <br />
                                      <u>{t('screens.myReleases.feedPathToItem')}</u>
                                      {notif.pathToItem}
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            ))}
                          </React.Fragment>
                        )}
                      </p>
                    </div>
                  </div>
                )}
                {pendingSubmission && (
                  <div className="card mb-2">
                    <div className="card-header bg-transparent">
                      <h5 className="card-title">
                        {t('screens.myReleases.pendingSubmission')}
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={['fad', t(`screens.myReleases.statusIcon.${pendingSubmission.status}`)]}
                          color={t(`screens.myReleases.statusColor.${pendingSubmission.status}`)}
                        />
                      </h5>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <strong>{t('screens.myReleases.releaseDate')}</strong>
                        {moment(pendingSubmission.releaseDate).format('DD/MM/YYYY HH:mm')}
                        <br />
                        <strong>{t('screens.myReleases.submissionDate')}</strong>
                        {moment(pendingSubmission.submissionDate).format('DD/MM/YYYY HH:mm')}
                        <br />
                        <strong>{t('screens.myReleases.versionNumber')}</strong>
                        {pendingSubmission.version}
                        <br />
                        {!!pendingSubmission.feed?.length && (
                          <React.Fragment>
                            <strong>{t('screens.myReleases.feed')}</strong>
                            {pendingSubmission.feed.map((notif, index) => (
                              <div
                                className="alert alert-danger mb-2"
                                role="alert"
                                key={`${pendingSubmission.submissionId}_feed_${index}`}
                              >
                                <div>
                                  <strong>{notif.message}</strong>
                                  {notif.pathToItem && (
                                    <React.Fragment>
                                      <br />
                                      <u>{t('screens.myReleases.feedPathToItem')}</u>
                                      {notif.pathToItem}
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            ))}
                          </React.Fragment>
                        )}
                      </p>
                    </div>

                    <div className="card-footer">
                      {['reviewing'].includes(pendingSubmission?.status) ? (
                        <em>{t('screens.myReleases.cancelSubmissionImpossible')}</em>
                      ) : (
                        <button
                          className="btn btn-outline-danger ml-2"
                          type="button"
                          id="button-cancel-pending"
                          onClick={cancelPendingSubmission}
                        >
                          {t('screens.myReleases.cancelSubmission')}
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {liveSubmission && (
                  <div className="card mb-2">
                    <div className="card-header bg-transparent">
                      <h5 className="card-title">
                        {t('screens.myReleases.lastLiveSubmission')}
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={['fad', t(`screens.myReleases.statusIcon.${liveSubmission.status}`)]}
                          color={t(`screens.myReleases.statusIcon.${liveSubmission.statusColor}`)}
                        />
                      </h5>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <strong>{t('screens.myReleases.releaseDate')}</strong>
                        {moment(liveSubmission.releaseDate).format('DD/MM/YYYY HH:mm')}
                        <br />
                        <strong>{t('screens.myReleases.submissionDate')}</strong>
                        {moment(liveSubmission.submissionDate).format('DD/MM/YYYY HH:mm')}
                        <br />
                        <strong>{t('screens.myReleases.reviewedOn')}</strong>
                        {moment(liveSubmission.reviewedOn).format('DD/MM/YYYY HH:mm')}
                        <br />
                        <strong>{t('screens.myReleases.versionNumber')}</strong>
                        {liveSubmission.submittableVersion}
                        <br />
                        <strong>{t('screens.myReleases.status')}</strong>
                        {t(`screens.myReleases.statusList.${liveSubmission.status}`)}
                        <br />
                      </p>
                    </div>
                  </div>
                )}

                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">{t('screens.myReleases.newSubmission')}</h5>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      <strong>{t('screens.myReleases.releaseDate')}</strong>
                      {moment(scenarioReleaseInfo.devReleaseDate).format('DD/MM/YYYY HH:mm')}
                      <br />
                      <strong>{t('screens.myReleases.versionNumber')}</strong>
                      {scenarioReleaseInfo.submittableVersion || '??'}
                      <br />
                    </p>
                    <InputString
                      style={{ marginRight: 5 }}
                      fieldName="submissionMessage"
                      value={submissionMessage}
                      multiline
                      label={t('screens.myReleases.submissionMessageLabel')}
                      help={t('screens.myReleases.submissionMessagePlaceholder')}
                      handleChange={handleChange}
                    />
                  </div>

                  <div className="card-footer">
                    {['pendingReview'].includes(pendingSubmission?.status) && (
                      <em>{t('screens.myReleases.alreadyPending')}</em>
                    )}
                    <button
                      className="btn btn-outline-warning ml-2"
                      type="button"
                      id="button-deploy"
                      onClick={() => submitRelease()}
                      disabled={!canSubmit}
                    >
                      {t('screens.myReleases.submitPublic')}
                    </button>
                    <button className="btn btn-outline ml-2" type="button" id="button-deploy" disabled>
                      {t('screens.myReleases.submitInvisible')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>{!scenarioReleaseInfo.length && <h6>{t('screens.myReleases.noScenarioSelected')}</h6>}</div>
          )}
        </div>
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  cancelPendingSubmission: ScenarioServiceHelper.cancelPendingSubmissionAsync,
  submitScenario: ScenarioServiceHelper.submitScenarioAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withConfirm,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(MyRealeases);
