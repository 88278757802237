/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Loader, InputSelect } from 'src/pages/components';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { AmsServiceHelper } from 'src/store/ams';
import type { ObjectMap, City } from 'src/data';
import VisibilitySensor from 'react-visibility-sensor';

import { TabContent } from '../components';

type AMSInfo = {
  id: string,
  lastVersion: string,
  currentVersion?: string,
};

type Props = {
  bulkDeployAmsAsync: FirebaseHelper.bulkDeployAmsAsyncType,
  bulkDeployToInternalAsync: AmsServiceHelper.bulkDeployToInternalAsyncType,
  firebase: Firebase,
  cities: City[],
  locale: string,
  t: (key: string) => string,
};

type State = {
  currentAMSs: ObjectMap<AMSInfo>,
  newAMSs: ObjectMap<AMSInfo>,
  filteredAMSs: ObjectMap<AMSInfo>,
  amsToDeployIds: string[],
  isLoading: boolean,
  cityId?: string,
  searchString?: string,
};

class AmsTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    currentAMSs: {},
    newAMSs: {},
    filteredAMSs: {},
    amsToDeployIds: [],
    isLoading: false,
    cityId: undefined,
    searchString: '',
  };

  onVisibilityChanged = (visible: boolean) => {
    if (visible) {
      this.reloadAMSsAsync();
    }
  };

  reloadAMSsAsync = async () => {
    try {
      this.setState({ isLoading: true });
      const { newAMSs, currentAMSs } = await this.props.firebase.getAMSsToDeployAsync();
      this.setState({ newAMSs, currentAMSs, isLoading: false }, this.updateFilteredAms);
    } catch (error) {
      console.warn('Cannot load AMSs', error);
      this.setState({ isLoading: false });
    }
  };

  handleChange = (event) => {
    const { options } = event.target;
    const values = [];
    const fieldName = event.target.id;
    for (let i = 0, l = options.length; i < l; i += 1) {
      const option = options[i];
      if (option.selected) {
        values.push(option.value);
      }
    }
    this.setState({ [fieldName]: values });
    const newVal = { ...this.state };
    newVal[fieldName] = values;
  };

  handleCityChange = (event) => {
    const { value } = event.target;
    // $FlowFixMe Boolean is only used for bool fields
    this.setState({ cityId: value, amsToDeployIds: [] }, this.updateFilteredAms);
  };

  updateFilteredAms = () => {
    const { cityId } = this.state;
    const filteredAMSs = {};
    const allAMSs = { ...this.state.currentAMSs, ...this.state.newAMSs };
    if (allAMSs) {
      Object.keys(allAMSs).forEach((it: string) => {
        if (cityId) {
          // $FlowFixMe indexer
          if (it.startsWith(cityId)) {
            filteredAMSs[it] = allAMSs[it];
          }
        } else {
          // $FlowFixMe indexer
          filteredAMSs[it] = allAMSs[it];
        }
      });
    }
    this.setState({ filteredAMSs });
  };

  dryRunBulkGenerateReleaseAsync = async () => {
    await this._bulkDeployToInternalAsync(true);
  };

  bulkGenerateReleaseAsync = async () => {
    await this._bulkDeployToInternalAsync(false);

    await this.reloadAMSsAsync();
  };

  _bulkGenerateReleaseAsync = async (dryRun: boolean) => {
    const { bulkDeployAmsAsync } = this.props;
    const { amsToDeployIds } = this.state;
    if (bulkDeployAmsAsync) {
      try {
        this.setState({ isLoading: true });
        await bulkDeployAmsAsync(amsToDeployIds, dryRun);
        this.setState({ isLoading: false, amsToDeployIds: [] });
      } catch (error) {
        console.error(error);
        this.setState({ isLoading: false });
      }
    }
  };

  _bulkDeployToInternalAsync = async (dryRun: boolean) => {
    const { bulkDeployToInternalAsync, firebase } = this.props;
    const { amsToDeployIds } = this.state;
    if (bulkDeployToInternalAsync) {
      try {
        this.setState({ isLoading: true });
        await bulkDeployToInternalAsync(amsToDeployIds, firebase, dryRun);
        this.setState({ isLoading: false, amsToDeployIds: [] });
      } catch (error) {
        console.error(error);
        this.setState({ isLoading: false });
      }
    }
  };

  itemToLabel = (item: AMSInfo) => {
    let res = item.id;
    if (item.currentVersion) {
      res = `${res} - ${item.currentVersion}=>${item.lastVersion}`;
    } else {
      res = `${res} [NEW]`;
    }
    return res;
  };

  renderSelectField = (fieldName, value, label, values, help = undefined, disabled = false, multiple = false) => (
    <div className="form-group" key={fieldName}>
      <label htmlFor={fieldName}>{label}</label>
      <select
        className="form-control"
        id={fieldName}
        onChange={this.handleChange}
        value={value}
        disabled={disabled}
        aria-describedby={`${fieldName}Help`}
        placeholder={''}
        multiple={multiple}
      >
        {multiple || <option value={''}>{''}</option>}
        {values &&
          /* $FlowFixMe: Object.values */
          Object.values(values).map((element: AMSInfo) => (
            <option key={element.id} value={element.id}>
              {this.itemToLabel(element)}
            </option>
          ))}
      </select>
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { amsToDeployIds, filteredAMSs, isLoading } = this.state;
    const { locale, t } = this.props;
    return (
      <TabContent name="ams">
        <VisibilitySensor onChange={this.onVisibilityChanged} partialVisibility>
          <React.Fragment>
            <div className="card bg-light screenBlock mb-3">
              <div className="card-header">
                <h3>{t('screens.admin.ams.sectionTitle')}</h3>
              </div>
              <div className="card-body">
                <InputSelect
                  fieldName={'cityId'}
                  value={this.state.cityId}
                  values={this.props.cities}
                  itemToId={(it) => it.id}
                  itemToTitle={(it) => it.name.valueForLocale(locale)}
                  label={t('screens.admin.ams.selectIdLabel')}
                  handleChange={this.handleCityChange}
                />
                {this.renderSelectField(
                  'amsToDeployIds',
                  amsToDeployIds,
                  t('screens.admin.ams.city'),
                  filteredAMSs,
                  undefined,
                  false,
                  true,
                )}
                <button
                  className="btn btn-outline-secondary mb-3"
                  type="button"
                  onClick={this.bulkGenerateReleaseAsync}
                  style={{ whiteSpace: 'normal' }}
                  disabled={!amsToDeployIds.length}
                >
                  {`${t('screens.admin.ams.deploy')} ${amsToDeployIds.join(', ')}`}
                </button>
                <button
                  className="btn btn-outline-secondary mb-3 ml-2"
                  type="button"
                  onClick={this.dryRunBulkGenerateReleaseAsync}
                  style={{ whiteSpace: 'normal' }}
                  disabled={!amsToDeployIds.length}
                >
                  {`${t('screens.admin.ams.deployDry')} ${amsToDeployIds.join(', ')}`}
                </button>
              </div>
            </div>
            {isLoading && <Loader />}
          </React.Fragment>
        </VisibilitySensor>
      </TabContent>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
  cities: Object.values(state.configuration.availableCities),
});

const mapDispatchToProps = {
  bulkDeployAmsAsync: FirebaseHelper.bulkDeployAmsAsync,
  bulkDeployToInternalAsync: AmsServiceHelper.bulkDeployToInternalAsync,
};

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(AmsTab);
