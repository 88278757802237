/**
 * @flow
 * @format
 * eslint-disable no-param-reassign
 */

// eslint-disable no-param-reassign
import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { Claims } from 'src/constants/roles';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Link } from 'react-router-dom';
import {
  BaseItem,
  ItemTypes,
  POIItemPOITypes,
  LocalizedStringArray,
  AMSItem,
  DocumentItem,
  AnecdoteItem,
  Discussion,
} from 'src/data';
import type { ItemProgressionType, MetricEvent } from 'src/data/BaseItem';
import type { screenPlayItemType } from 'src/store/configuration/ConfigurationReducer';
import { ItemsServiceHelper } from 'src/store/scenario/items';

import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import * as Routes from 'src/constants/routes';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import LocalizedFile from 'src/data/LocalizedFile';
import POIItem, { POIItemPatrolTypes } from 'src/data/POIItem';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import Loader from 'src/pages/components/loader';
import InputLocalizedFiles from 'src/pages/components/inputs/InputLocalizedFiles';
import InputLocalizedFile from 'src/pages/components/inputs/InputLocalizedFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  InputBoolean,
  InputString,
  InputSelect,
  InputNumber,
  InputProgress,
  InputMetrics,
  InputPoiTypes,
  InputLocks,
  InputJSONArray,
} from 'src/pages/components/inputs';
import { asyncForEach, getAltitude } from 'src/utils';
import * as Globals from 'src/constants/globals';
import { getNewAtlObject } from 'src/store/scenario/items/ItemsReducer';
import { controlActions } from 'src/data/BackgroundMusicControlsItem';
import { AmsServiceHelper } from '../../store/ams';
import InputMultipleJSONArray from './inputs/InputMultipleJSONArray';
import { itemTypeColorator } from '../scenario/components/graph/baseItem/BaseItemColorations';

type State = {
  id?: string,
  idSuffix: string,
  contentType: string,
  content?: string,
  isGoodAnswer: boolean,
  isValid: boolean,
  nextCustom?: string,
  clues?: string,
  metricEvents: MetricEvent[],
  progression: ItemProgressionType,
  filesToRemove: string[],
  hasChanges: boolean,
  isIdValid: boolean,
  isLoading: boolean,
  idName: string,
};

export type AnswerInputProps = {
  itemId?: string,
  isConfirmedEditor: boolean,
  disabled?: boolean,
  itemStates: screenPlayItemType,
  itemUnlockedValues: number[],
  nodeId: string,
  item: BaseItem,
  title: string,
  idPrefix: (state: any) => string,
  idSuffix: string,
  locale: string,
  isCreate?: boolean,
  createItem: AmsServiceHelper.createAmsType,
  updateItem: ItemsServiceHelper.updateItemType,
  deleteItem: ItemsServiceHelper.removeItemType,
  fieldsDescription: any,
  idRequiredFieldsDescription: any,
  marker: number[],
  polygon: number[][],
  polyline: number[][],
  t: (key: string) => string,
  scenarioId: string,
  firebase: Firebase,
  existingItemIds: string[],
  hideBaseItemFields?: boolean,
  hideClues?: boolean,
  addNotif: EventsServiceHelper.addNotifType,
  isAms?: boolean,
  idKey?: string,
  isEditingItem: boolean,
  startEditing: () => any,
  defaultCoordinate: number[],
  validityCheck?: { isValid: boolean, alerts: { level: string, message: string, extra?: any }[] },
  itemColor: string,
  displayModal: boolean,
  setDisplayModal: Dispatch<SetStateAction<boolean>>,
  createPOIItem: () => void,
  removePOIItem: () => void,
};

class DefaultItemInput extends React.PureComponent<AnswerInputProps, State> {
  static defaultProps = {
    idPrefix: undefined,
    idSuffix: '',
    canChangeId: true,
    idKey: 'nodeId',
  };

  state = {
    id: undefined,
    idName: '',
    contentType: '',
    metricEvents: [],
    progression: {},
    isGoodAnswer: false,
    isValid: false,
    hasChanges: false,
    clues: undefined,
    filesToRemove: [],
    isIdValid: false,
    isLoading: false,
    idSuffix: '',
    idPrefix: '',
  };

  componentDidMount() {
    this.setItemData(this.props, false, false, false, false);
  }

  componentDidUpdate = async (oldProps: AnswerInputProps, oldState: State) => {
    const { idKey } = this.props;
    // $FlowFixMe indexer
    if (oldProps.item && (!this.props.item || this.props.item[idKey] !== oldProps.item[idKey])) {
      await this.warnSaveIfNeeded(oldProps, oldState);
    }
    if (this.props.nodeId !== oldProps.nodeId) {
      this.setItemData(this.props, false, false, false, false);
    } else if (this.props.isAms && this.props.itemId !== oldProps.itemId) {
      this.setItemData(this.props, false, false, false, false);
    } else {
      if (oldProps.marker !== this.props.marker) {
        this.setItemData(this.props, true, false, false, true);
      }
      if (oldProps.polygon !== this.props.polygon) {
        this.setItemData(this.props, false, true, false, true);
      }
      if (oldProps.polyline !== this.props.polyline) {
        this.setItemData(this.props, false, false, true, true);
      }
    }
  };

  componentWillUnmount() {
    this.warnSaveIfNeeded();
  }

  warnSaveIfNeeded = async (props?: AnswerInputProps = this.props, state?: State = this.state) => {
    if (this.state.hasChanges && (props.scenarioId || props.isAms)) {
      const updateData = await this.getDataToSave(props, state);
      const { isValid } = state;
      if (isValid && (props.scenarioId || props.isAms)) {
        this.updateWithData(updateData, false, false);
      } else {
        this.props.addNotif(
          NotificationTypes.ERROR,
          !isValid ? 'E_UNSAVED_ITEM_INVALID' : 'E_UNSAVED_ITEM',
          undefined,
          0,
        );
      }
    }
  };

  loadFields = (
    item,
    description,
    newState,
    locale,
    marker,
    polygon,
    polyline,
    markerOnly: boolean,
    polygonOnly: boolean,
    polylineOnly: boolean,
  ) => {
    const doAll = !markerOnly && !polygonOnly && !polylineOnly;
    description.forEach((desc) => {
      switch (desc.type) {
        case 'localizedString': {
          if (doAll) {
            // $FlowFixMe indexer
            const value = item[desc.name];
            const translated = value && value.valueForLocale(locale);
            newState[desc.name] = translated;
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'latLngAlt':
        case 'coordinate': {
          if (doAll || markerOnly || (polylineOnly && desc.handlePolyline)) {
            let textValue;
            if (marker) {
              textValue = JSON.stringify(marker);
            } else if (polylineOnly && polyline) {
              textValue = JSON.stringify(desc.handlePolyline(newState, polyline).coordinate);
            } else {
              // $FlowFixMe
              const value = item[desc.name];

              if (value) {
                const objectValue = [value.latitude, value.longitude];
                if (value.altitude) {
                  objectValue.push(value.altitude);
                }
                textValue = JSON.stringify(objectValue, null, '\t');
              }
            }
            // $FlowFixMe indexer
            newState[desc.name] = textValue;
          } else {
            // $FlowFixMe indexer
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'multipleLatLngAlt': {
          if (doAll || polylineOnly) {
            let textValue;
            if (polyline) {
              let polylineTohandle = polyline;
              if (desc.handlePolyline) {
                polylineTohandle = desc.handlePolyline(newState, polyline).polyline;
              }
              textValue = JSON.stringify(polylineTohandle, undefined, 4);
            } else {
              // $FlowFixMe
              const values = item[desc.name];
              const finalArray = values.reduce((all, cur) => {
                const objectValue = [cur.latitude, cur.longitude];
                if (cur.altitude) {
                  objectValue.push(cur.altitude);
                }
                all.push(objectValue);
                return all;
              }, []);
              // Transform an array as a string
              const handleString = (it: Array<string>) => {
                // There is an altitude
                if (it[2]) {
                  return `[${it[0]}, ${it[1]}, ${it[2]}]`;
                }
                return `[${it[0]}, ${it[1]}]`;
              };
              textValue = finalArray && `[\n\t${finalArray.map(handleString).join(',\n\t')}\n]`;
            }
            newState[desc.name] = textValue;
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'GameArea': {
          if (doAll || polygonOnly) {
            let textValue;
            if (polygon) {
              textValue = JSON.stringify(polygon, undefined, 4);
            } else {
              // $FlowFixMe indexer
              const value = item[desc.name];
              textValue = value && `[\n\t${value.map((it) => `[${it[0]}, ${it[1]}]`).join(',\n\t')}\n]`;
            }
            newState[desc.name] = textValue;
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'DocumentItemLock':
        case 'poiTypes':
          if (doAll) {
            // $FlowFixMe indexer
            newState[desc.name] = item[desc.name];
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        case 'jsonArray':
        case 'json': {
          if (doAll) {
            // $FlowFixMe indexer
            const value = item[desc.name];
            newState[desc.name] = JSON.stringify(value, undefined, '\t');
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        }
        case 'number':
          if (doAll) {
            // $FlowFixMe indexer
            newState[desc.name] = `${item[desc.name]}`;
            // $FlowFixMe indexer
            if (item[desc.name] === undefined) {
              newState[desc.name] = '';
            }
          } else {
            newState[desc.name] = this.state[desc.name];
          }
          break;
        default:
          if (doAll) {
            // $FlowFixMe indexer
            newState[desc.name] = item[desc.name] || '';
          } else if (polylineOnly && desc.handlePolyline) {
            const value = desc.handlePolyline(newState, polyline);
            if (value) {
              // $FlowFixMe indexer
              newState[desc.name] = value;
            } else {
              // $FlowFixMe indexer
              newState[desc.name] = this.state[desc.name];
            }
          } else {
            // $FlowFixMe indexer
            newState[desc.name] = this.state[desc.name];
          }
          break;
      }
    });
  };

  setItemData = (
    props: AnswerInputProps,
    markerOnly: boolean,
    polygonOnly: boolean,
    polylineOnly: boolean,
    isUpdate: boolean,
  ) => {
    const {
      locale,
      item,
      fieldsDescription,
      idRequiredFieldsDescription,
      idPrefix,
      idSuffix,
      marker,
      polygon,
      polyline,
    } = props;
    const id = item && item.id;
    let idName;
    const idPrefixVal = idPrefix ? idPrefix(item || this.state) : '';
    if (idSuffix && idSuffix.length) {
      idName = id ? id.slice(idPrefixVal.length, -1 * idSuffix.length) : '';
    } else {
      idName = id ? id.slice(idPrefixVal.length) : '';
    }
    const val = item && item.clues && item.clues.valueForLocale(locale, true);
    const newState = {
      idName: idName || '',
      clues: val && val.length ? JSON.stringify(val) : '[]',
      progression: item && item.progression,
      metricEvents: item && item.metricEvents,
      hasChanges: isUpdate,
      filesToRemove: [],
    };
    if (item) {
      this.loadFields(
        item,
        idRequiredFieldsDescription,
        newState,
        locale,
        marker,
        polygon,
        polyline,
        markerOnly,
        polygonOnly,
        polylineOnly,
      );
      this.loadFields(
        item,
        fieldsDescription,
        newState,
        locale,
        marker,
        polygon,
        polyline,
        markerOnly,
        polygonOnly,
        polylineOnly,
      );
    }
    this.setState(newState);
    this.updateValidity(newState);
  };

  handleFileChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const { files } = event.target;
    // $FlowFixMe Object.values
    const fileNames = Object.values(files).map((file) => file.name);
    this.handleChange({ target: { value: fileNames, id: event.target.id } });
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal) => {
    const { idPrefix, idSuffix, existingItemIds } = this.props;
    const { idName } = newVal;
    const idPrefixVal = idPrefix ? idPrefix(this.state) : '';
    const itemId = idPrefixVal + idName + idSuffix;
    const isIdValid = idName.length && itemId.match(Globals.idRegex) && !existingItemIds.includes(itemId);

    const isValid = !!this.props.item && (!!this.props.item.id || !!isIdValid);
    this.setState({ isIdValid: !!isIdValid, isValid });
  };

  deleteItem = async () => {
    const { deleteItem, item, firebase } = this.props;
    if (item) {
      deleteItem(item.id, firebase);
    }
  };

  getFieldsDataToSave = async (fields, newItem, state, locale) => {
    await asyncForEach(fields, async (desc) => {
      const stateVal = state[desc.name];
      switch (desc.type) {
        case 'localizedString': {
          if (stateVal) {
            // $FlowFixMe indexer
            newItem[desc.name].setValueForLocale(stateVal, locale);
          } else {
            // $FlowFixMe indexer
            newItem[desc.name].flush();
          }
          break;
        }
        case 'coordinate': {
          const json = JSON.parse(stateVal);
          // $FlowFixMe indexer
          newItem[desc.name] = json && {
            latitude: json[0],
            longitude: json[1],
          };
          if (json[2] !== undefined) {
            // $FlowFixMe indexer
            newItem[desc.name].altitude = json[2];
          }
          break;
        }
        case 'latLngAlt': {
          if (!stateVal) {
            break;
          }
          const json = JSON.parse(stateVal);
          let value = json;
          if (value.length < 3) {
            try {
              // DEPRECATED: always return 0
              value = await getAltitude(value[0], value[1]);
            } catch (error) {
              console.log('Could not get altitude for item', json);
            }
          }
          // $FlowFixMe indexer
          newItem[desc.name] = value && {
            latitude: value[0],
            longitude: value[1],
            altitude: value[2],
          };
          break;
        }
        case 'multipleLatLngAlt':
          {
            if (!stateVal) {
              break;
            }
            const json = JSON.parse(stateVal);
            if (json) {
              const finalArray = [];
              // eslint-disable-next-line no-restricted-syntax
              for (let item of json) {
                if (item) {
                  if (item.length < 3) {
                    try {
                      // DEPRECATED: always return 0
                      // eslint-disable-next-line no-await-in-loop
                      item = await getAltitude(item[0], item[1]);
                    } catch (error) {
                      console.debug('Could not get altitude for item', item);
                    }
                  }

                  // $FlowFixMe indexer
                  finalArray.push({
                    latitude: item[0],
                    longitude: item[1],
                    altitude: item[2],
                  });
                }
              }
              newItem[desc.name] = finalArray;
            }
          }
          break;
        case 'GameArea': {
          const json = JSON.parse(stateVal);
          // $FlowFixMe indexer
          newItem[desc.name] = json;
          break;
        }
        case 'number': {
          // $FlowFixMe indexer
          newItem[desc.name] = stateVal && stateVal.length ? parseInt(stateVal, 10) : undefined;
          break;
        }
        case 'DocumentItemLock':
        case 'poiTypes': {
          // $FlowFixMe indexer
          newItem[desc.name] = stateVal;
          break;
        }
        case 'jsonArray':
        case 'json': {
          const json = JSON.parse(state[desc.name]);
          // $FlowFixMe indexer
          newItem[desc.name] = json;
          break;
        }
        case 'boolean': {
          // $FlowFixMe indexer
          newItem[desc.name] = stateVal !== '' ? stateVal : false;
          break;
        }
        default:
          // $FlowFixMe indexer
          newItem[desc.name] = stateVal;
          break;
      }
    });
    return newItem;
  };

  getDataToSave = async (props?: AnswerInputProps = this.props, state?: State = this.state) => {
    const {
      locale,
      item,
      fieldsDescription,
      idRequiredFieldsDescription,
      idPrefix,
      idSuffix,
      scenarioId,
      isCreate,
      updateItem,
      createItem,
    } = props;

    if (!item) {
      return undefined;
    }

    let newItem = getNewAtlObject(item);
    const idPrefixVal = idPrefix ? idPrefix(state) : '';
    newItem.id = idPrefixVal + state.idName + idSuffix;

    if (newItem instanceof BaseItem) {
      newItem.clues = new LocalizedStringArray(`${item.id}_clues`, item.clues, false);
      const parsedClues = JSON.parse(state.clues || '[]');
      newItem.clues.setValueForLocale(parsedClues, locale);
      newItem.clues.initAllLocalesIfRequired();
      newItem.metricEvents = state.metricEvents;
      newItem.progression = state.progression;
    }

    newItem = await this.getFieldsDataToSave(idRequiredFieldsDescription, newItem, state, locale);
    newItem = await this.getFieldsDataToSave(fieldsDescription, newItem, state, locale);
    const { filesToRemove } = state;
    return {
      newItem,
      filesToRemove: [...filesToRemove],
      scenarioId,
      isCreate,
      updateItem,
      createItem,
    };
  };

  updateWithData = async (updateData, notifyUi: boolean = false, sendNotif?: boolean = true) => {
    const { firebase, deleteItem } = this.props;
    if (updateData) {
      const { filesToRemove, newItem, scenarioId, isCreate, updateItem, createItem } = updateData;
      if (notifyUi) {
        this.setState({ isLoading: true });
      }
      if (filesToRemove && filesToRemove.length) {
        await FirebaseHelper.removeEditorFilesAsync(scenarioId, filesToRemove, 'scenario', firebase);
      }
      if (updateItem) {
        const posUpdated = {
          ...newItem.pos,
          y: newItem.pos.y + 175,
        };
        if (newItem.type === ItemTypes.DocumentPOI) {
          const id = newItem.id;
          const newDocument = new DocumentItem({
            id: id.replace('docpoi', 'doc'),
            pos: { ...posUpdated },
          });
          const newPOI = new POIItem({
            id: id.replace('docpoi', 'poi'),
            pos: newItem.pos,
            poiTypes: {
              Default: POIItemPOITypes.Interactive,
            },
          });
          await updateItem(scenarioId, newItem.id, newItem, firebase, false);
          await updateItem(scenarioId, newDocument.id, newDocument, firebase, false);
          await updateItem(scenarioId, newPOI.id, newPOI, firebase, sendNotif);
          deleteItem(scenarioId, newItem, newItem.nodeId);
          this.props.removePOIItem(newItem);
        } else if (newItem.type === ItemTypes.AnecdotePOI) {
          const id = newItem.id;
          const newAnecdote = new AnecdoteItem({
            id: id.replace('anecdotepoi', 'anecdote'),
            pos: { ...posUpdated },
          });
          const newPOI = new POIItem({
            id: id.replace('anecdotepoi', 'poi'),
            pos: newItem.pos,
            autocompleteAfterLaunch: true,
            launchOnFirstReach: true,
            poiTypes: {
              Default: POIItemPOITypes.Interactive,
            },
          });
          await updateItem(scenarioId, newItem.id, newItem, firebase, false);
          await updateItem(scenarioId, newAnecdote.id, newAnecdote, firebase, false);
          await updateItem(scenarioId, newPOI.id, newPOI, firebase, sendNotif);
          deleteItem(scenarioId, newItem, newItem.nodeId);
          this.props.removePOIItem(newItem);
        } else if (newItem.type === ItemTypes.DiscussionPOI) {
          const { id } = newItem;
          const newDiscussion = new Discussion({
            id: id.replace('discpoi', 'disc'),
            pos: { ...posUpdated },
          });
          const newPOI = new POIItem({
            id: id.replace('discpoi', 'poi'),
            pos: newItem.pos,
            visibleOnMap: true,
            poiTypes: {
              Default: POIItemPOITypes.NPC,
            },
          });
          await updateItem(scenarioId, newItem.id, newItem, firebase, false);
          await updateItem(scenarioId, newDiscussion.id, newDiscussion, firebase, false);
          await updateItem(scenarioId, newPOI.id, newPOI, firebase, sendNotif);
          deleteItem(scenarioId, newItem, newItem.nodeId);
          this.props.removePOIItem(newItem);
        } else if (scenarioId && newItem instanceof BaseItem) {
          await updateItem(scenarioId, newItem.id, newItem, firebase, sendNotif);
        } else if (isCreate) {
          await createItem(newItem, true, firebase);
        } else {
          // $FlowFixMe TODO: See how to do it better
          await updateItem(scenarioId, newItem.id, newItem, firebase, sendNotif);
        }
        if (notifyUi) {
          this.setState({ hasChanges: false, isLoading: false });
        }
      }
    }
  };

  updateItem = async () => {
    if (this.state.isValid) {
      const updateData = await this.getDataToSave();
      this.updateWithData(updateData, true);
    }
  };

  renderFileField = (fieldName, value, label, help = undefined, disabled = false, multiple = false) => (
    <div className="form-group" key={fieldName}>
      <label htmlFor={fieldName}>{label}</label>
      <div className="custom-file">
        <input
          type="file"
          className="form-control custom-file-input"
          id={fieldName}
          onChange={this.handleFileChange}
          disabled={disabled || this.props.disabled}
          aria-describedby={`${fieldName}Help`}
          placeholder={'...'}
          multiple={multiple}
          style={{ border: 'none', backgroundColor: '#f0f5fa' }}
        />
        <label className="custom-file-label" style={{ overflow: 'hidden', wordWrap: '...' }} htmlFor={fieldName}>
          {value && value.join(', ')}
        </label>
      </div>
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );

  fileSuffix = (fieldName) => {
    const { fieldsDescription } = this.props;
    const desc = fieldsDescription.find((it) => it.name === fieldName);
    return desc && desc.suffix;
  };

  addFile = (fieldName) => {
    const field = [...this.state[fieldName]];
    const max = field.reduce((acc, cur) => (cur.index >= acc ? cur.index + 1 : acc), 0);
    const suffix = this.fileSuffix(fieldName);
    field.push(new LocalizedFile(this.props.item.id, suffix, { index: max }));
    this.setState({ [fieldName]: field, hasChanges: true });
  };

  removeFile = (fieldName, index) => {
    let field = [...this.state[fieldName]];
    const fileToRemove = field.find((it) => it.index === index);
    field = field.filter((it) => it.index !== index);
    this.setState({ [fieldName]: field, hasChanges: true });
    const itemFilesToRemove = fileToRemove.listStorageFiles();
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleFileSelected = (fieldName: string, locale: string, file: File, index: ?number = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const suffix = this.fileSuffix(fieldName);
    const newFile = new LocalizedFile(this.props.item.id, suffix, oldFile);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;
    newFile.files[locale].name = file.name;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleContentChange = (fieldName: string, value: string, index: ?number = undefined) => {
    const { locale } = this.props;
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const suffix = this.fileSuffix(fieldName);
    const newFile = new LocalizedFile(this.props.item.id, suffix, oldFile);
    newFile.content.values[locale] = value;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  addFileLocale = (fieldName: string, locale: string, index = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const suffix = this.fileSuffix(fieldName);
    const newFile = new LocalizedFile(this.props.item.id, suffix, oldFile);
    newFile.addLocale(locale);
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  removeFileLocale = (fieldName: string, locale: string, index = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const suffix = this.fileSuffix(fieldName);
    const newFile = new LocalizedFile(this.props.item.id, suffix, oldFile);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }

    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  renderCommonFields = () => [
    // eslint-disable-next-line react/jsx-key
    <InputJSONArray
      className="pb-2"
      key="clues"
      fieldName="clues"
      onFocus={this.onFieldFocus}
      value={this.state.clues}
      label={this.props.t('screens.scenarioEdition.baseItemEdition.cluesLabel')}
      handleChange={this.handleChange}
      hidden={this.props.hideClues}
      disabled={this.props.disabled}
      inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
      prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
    />,
    // eslint-disable-next-line react/jsx-key
    <InputMetrics
      key="metricEvents"
      fieldName="metricEvents"
      onFocus={this.onFieldFocus}
      value={this.state.metricEvents}
      itemUnlockedStates={this.props.itemUnlockedValues}
      label={this.props.t('screens.scenarioEdition.baseItemEdition.metricEventsLabel')}
      availableStates={this.props.itemStates}
      help={this.props.t('screens.scenarioEdition.baseItemEdition.progressionHelp')}
      handleChange={this.handleChange}
      hidden={this.props.hideBaseItemFields}
      disabled={this.props.disabled}
    />,
    // eslint-disable-next-line react/jsx-key
    <InputProgress
      key="progression"
      fieldName="progression"
      onFocus={this.onFieldFocus}
      value={this.state.progression}
      label={this.props.t('screens.scenarioEdition.baseItemEdition.progressionLabel')}
      availableStates={this.props.itemStates}
      help={this.props.t('screens.scenarioEdition.baseItemEdition.progressionHelp')}
      handleChange={this.handleChange}
      hidden={this.props.hideBaseItemFields}
      disabled={this.props.disabled}
      renderTitleFunction={(title) => this.props.t(`triggers.${title.toLowerCase()}`).toUpperCase()}
      t={this.props.t}
    />,
  ];

  renderFields = (item, fieldsDescription, locale) =>
    fieldsDescription.map((descr) => {
      const {
        type,
        name,
        value,
        values,
        disabled,
        multiline,
        multiple,
        accept,
        sizeErrorLimit,
        separatorBefore,
        hidden,
        isNumber,
        itemToTitle,
        text,
        suffixLabel,
      } = descr;
      const { t, restrictedFields, isConfirmedEditor } = this.props;
      if (restrictedFields?.includes(name) && !isConfirmedEditor) {
        return;
      }
      const forceMultiline =
        type === 'coordinate' ||
        type === 'latLngAlt' ||
        type === 'multipleLatLngAlt' ||
        type === 'GameArea' ||
        type === 'json';
      const label = t(`screens.scenarioEdition.baseItemEdition.${name}Label`);
      const suffixText = t(`screens.scenarioEdition.baseItemEdition.${suffixLabel}Label`);
      const help = t(`screens.scenarioEdition.baseItemEdition.${name}Help`);
      switch (type) {
        case 'string':
        case 'json':
        case 'localizedString':
          return (
            <InputString
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name] || ''}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              multiline={multiline || forceMultiline}
              helpInfos={t(`helpStrings:scenario.dashboard.input.${type}`, { returnObjects: true })}
              hidden={hidden && hidden(this.state, this.props)}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
          );
        case 'coordinate':
        case 'latLngAlt':
        case 'jsonArray':
          return (
            <InputJSONArray
              key={name}
              onFocus={this.onFieldFocus}
              className="pb2"
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name] || '[]'}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              helpInfos={t(`helpStrings:scenario.dashboard.input.${type}`, { returnObjects: true })}
              hidden={hidden && hidden(this.state, this.props)}
              isCoordinates={type !== 'jsonArray'}
              isNumber={isNumber}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
              prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
            />
          );
        case 'multipleLatLngAlt':
        case 'GameArea':
          return (
            <InputMultipleJSONArray
              key={name}
              onFocus={this.onFieldFocus}
              className="pb2"
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name] || '[[]]'}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              helpInfos={t(`helpStrings:scenario.dashboard.input.${type}`, { returnObjects: true })}
              hidden={hidden && hidden(this.state, this.props)}
              isCoordinates
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
          );
        case 'DocumentItemLock':
          return (
            <InputLocks
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              helpStrings={t(`helpStrings:scenario.dashboard.input.${type}`, { returnObjects: true })}
              hidden={hidden && hidden(this.state, this.props)}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
          );
        case 'poiTypes':
          return (
            <InputPoiTypes
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              itemUnlockedStates={this.props.itemUnlockedValues}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              helpStrings={t(`helpStrings:scenario.dashboard.input.${type}`, { returnObjects: true })}
              hidden={hidden && hidden(this.state, this.props)}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
          );
        case 'number':
          return (
            <InputNumber
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              hidden={hidden && hidden(this.state, this.props)}
              prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
              suffix={suffixText ?? label}
            />
          );
        case 'boolean':
          return (
            <InputBoolean
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={false}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              hidden={hidden && hidden(this.state, this.props)}
            />
          );
        case 'files':
          return this.renderFileField(name, this.state[name], label, help, disabled, multiple);
        case 'LocalizedFile':
          return (
            <InputLocalizedFile
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || !item || !item.id || this.props.disabled}
              handleFileSelected={this.handleFileSelected}
              handleContentChange={this.handleContentChange}
              addFileLocale={this.addFileLocale}
              removeFileLocale={this.removeFileLocale}
              contentLocale={locale}
              hidden={hidden && hidden(this.state, this.props)}
              accept={accept}
              sizeErrorLimit={sizeErrorLimit}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
          );
        case 'LocalizedFiles':
          return (
            <InputLocalizedFiles
              key={name}
              onFocus={this.onFieldFocus}
              separatorBefore={separatorBefore}
              fieldName={name}
              value={this.state[name]}
              label={label}
              help={help}
              disabled={disabled || !item || !item.id || this.props.disabled}
              addFile={() => this.addFile(name)}
              removeFile={this.removeFile}
              handleFileSelected={this.handleFileSelected}
              handleContentChange={this.handleContentChange}
              addFileLocale={this.addFileLocale}
              removeFileLocale={this.removeFileLocale}
              contentLocale={locale}
              hidden={hidden && hidden(this.state, this.props)}
              accept={accept}
              sizeErrorLimit={sizeErrorLimit}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
          );
        case 'enum':
          return (
            <InputSelect
              key={name}
              fieldName={name}
              onFocus={this.onFieldFocus}
              value={this.state[name]}
              values={values}
              itemToId={(it) => it}
              itemToTitle={itemToTitle || ((it) => it)}
              label={label}
              help={help}
              disabled={disabled || this.props.disabled}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
          );
        case 'link':
          return (
            <Link key={value} to={value}>
              <button className="btn btn-primary full-width mb-3" type="button" id={name} disabled={disabled}>
                {name}
              </button>
            </Link>
          );
        case 'checkboxLabel':
          return <label className="font-weight-bold text-capitalize">{label}</label>;
        case 'spacer':
          return <div className="mt-5"></div>;
        default:
          return <div key={name}>{`${name}: ${this.state[name]}(${type})`}</div>;
      }
    });

  createPOIItem = async () => {
    const updateData = await this.getDataToSave();
    const posUpdated = {
      ...updateData.newItem.pos,
      y: updateData.newItem.pos.y + 175,
    };
    if (updateData.newItem.type === ItemTypes.DocumentPOI) {
      const { id } = updateData.newItem;
      const newDocument = new DocumentItem({ id: id.replace('docpoi', 'doc'), pos: { ...posUpdated } });
      const newPOI = new POIItem({
        id: id.replace('docpoi', 'poi'),
        pos: updateData.newItem.pos,
      });
      this.props.createPOIItem(newPOI, newDocument, ItemTypes.DocumentPOI);
    } else if (updateData.newItem.type === ItemTypes.AnecdotePOI) {
      const { id } = updateData.newItem;
      const newAnecdote = new AnecdoteItem({ id: id.replace('anecdotepoi', 'anecdote'), pos: { ...posUpdated } });
      const newPOI = new POIItem({
        id: id.replace('anecdotepoi', 'poi'),
        pos: updateData.newItem.pos,
      });
      this.props.createPOIItem(newPOI, newAnecdote, ItemTypes.AnecdotePOI);
    } else if (updateData.newItem.type === ItemTypes.DiscussionPOI) {
      const { id } = updateData.newItem;
      const newDiscussion = new Discussion({ id: id.replace('discpoi', 'disc'), pos: { ...posUpdated } });
      const newPOI = new POIItem({
        id: id.replace('discpoi', 'poi'),
        pos: updateData.newItem.pos,
      });
      this.props.createPOIItem(newPOI, newDiscussion, ItemTypes.DiscussionPOI);
    }
  };

  render() {
    const {
      title,
      item,
      fieldsDescription,
      idRequiredFieldsDescription,
      locale,
      idPrefix,
      idSuffix,
      validityCheck,
      t,
    } = this.props;
    const { idName, isValid, isIdValid, isLoading } = this.state;

    const idPrefixVal = idPrefix ? idPrefix(this.state) : '';
    return item ? (
      <div
        className="card bg-light screenBlock pb-2"
        style={{ height: '100%', overflow: 'hidden', borderRadius: '10px', backgroundColor: 'white', border: 'none' }}
      >
        <div
          className="card-header"
          ref={(node) => {
            if (node) {
              node.style.setProperty('background-color', this.props.itemColor, 'important');
            }
          }}
          style={{ border: 'none', height: '65px', alignItems: 'center' }}
        >
          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={() => {
              this.createPOIItem();
              this.updateItem();
            }}
            disabled={!isValid || this.props.disabled}
            style={{
              float: 'left',
              marginBottom: '8px',
            }}
          >
            <FontAwesomeIcon icon={['fas', 'save']} />
          </div>

          <h3
            className={`${item.type}_graph_title text-capitalize h-100 d-flex align-items-center padding-before`}
            style={{ maxWidth: '80%', display: 'inline-block' }}
          >
            {title}
          </h3>

          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={() => this.props.setDisplayModal(false)}
            disabled={!isValid || this.props.disabled}
            style={{ float: 'right', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>

          {/* {deleteItem && (
            <button
              className={'btn btn-outline-danger'}
              type="button"
              id="updateitem"
              onClick={this.deleteItem}
              disabled={!isValid || this.props.disabled}
            >
              <FontAwesomeIcon icon={['fad', 'times']} />
            </button>
          )} */}
        </div>
        {item && (
          <div
            className="pr-3 pl-3 pt-3 pb-3"
            style={{ height: '100%', overflowY: 'scroll', backgroundColor: 'white' }}
          >
            {idRequiredFieldsDescription && this.renderFields(item, idRequiredFieldsDescription, locale)}
            <InputString
              fieldName="idName"
              value={idName}
              onFocus={this.onFieldFocus}
              label={t('screens.scenarioEdition.baseItemEdition.idLabel')}
              help={t('screens.scenarioEdition.baseItemEdition.idPlaceholder', { suffix: idSuffix })}
              handleChange={this.handleChange}
              suffix={idSuffix}
              prefix={idPrefixVal}
              disabled={(!!item && !!item.id) || this.props.disabled}
              helpInfos={t('helpStrings:scenario.dashboard.input.id', { returnObjects: true })}
              prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
              inputStyle={
                !((!!item && !!item.id) || this.props.disabled)
                  ? { border: 'none', backgroundColor: '#f0f5fa' }
                  : { border: 'none' }
              }
            />
            {(!item || !item.id) && idName && !isIdValid && (
              <label style={{ color: 'red', fontSize: 12 }}>{t('general.idInvalid')}</label>
            )}
            {item && item.id && this.renderFields(item, fieldsDescription, locale)}
            {item && item.id && this.renderCommonFields()}
            {validityCheck && (
              <div className="item-alerts-container">
                <span className="item-alerts-title">
                  {t('screens.scenarioEdition.baseItemEdition.releaseIssuesTitle')}
                </span>
                {validityCheck.alerts.map((it, index) => (
                  <div key={index} className={`alert alert-${it.level === 'error' ? 'danger' : 'warning'} mb-2`}>
                    {t(`notifications.${it.message}`)}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    ) : (
      <></>
    );
  }
}

const fieldsDescriptionFromType = (type, item, items, state, scenarioId, t, fileSizeLimits, isConfirmedEditor) => {
  switch (type) {
    case ItemTypes.Anecdote || ItemTypes.AnecdotePOI:
      return [
        {
          type: 'localizedString',
          name: 'contentText',
          // $FlowFixMe mixed
          value: item.contentText,
          multiline: true,
          disabled: false,
          separatorBefore: true,
        },
      ];
    case ItemTypes.Archive:
      return [
        {
          type: 'LocalizedFiles',
          multiple: true,
          name: 'images',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.images,
          disabled: false,
          separatorBefore: true,
          accept: '.png,.jpg,.jpeg',
        },
        {
          type: 'LocalizedFile',
          multiple: true,
          name: 'video',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.video,
          accept: '.mp4',
          sizeErrorLimit: fileSizeLimits.video,
          separatorBefore: true,
          hidden: (state: State, props: AnswerInputProps) => props && !props.isConfirmedEditor,
        },
        {
          type: 'localizedString',
          name: 'name',
          // $FlowFixMe mixed
          value: item.name,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'subtitle',
          // $FlowFixMe mixed
          value: item.subtitle,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'contentText',
          // $FlowFixMe mixed
          value: item.contentText,
          multiline: true,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'annotations',
          // $FlowFixMe mixed
          value: item.annotations,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'accessibleInGame',
          // $FlowFixMe mixed
          value: item.accessibleInGame, // TODO: Default value
          disabled: false,
        },
      ];
    case ItemTypes.Checkpoint:
      return [];
    case ItemTypes.Comment:
      return [
        {
          type: 'localizedString',
          name: 'contentText',
          // $FlowFixMe mixed
          value: item.contentText,
          multiline: true,
          disabled: false,
          separatorBefore: true,
        },
      ];
    case ItemTypes.Custom:
      return [
        {
          type: 'string',
          name: 'customContent',
          // $FlowFixMe mixed
          value: item.customContent,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'json',
          name: 'additionalInfo',
          // $FlowFixMe mixed
          value: item.additionalInfo,
          multiline: true,
          disabled: false,
        },
      ];
    case ItemTypes.Document || ItemTypes.DocumentPOI:
      return [
        {
          type: 'LocalizedFiles',
          multiple: true,
          name: 'images',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.images,
          disabled: false,
          separatorBefore: true,
          accept: '.png,.jpg,.jpeg',
        },
        {
          type: 'localizedString',
          name: 'name',
          // $FlowFixMe mixed
          value: item.name,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'additionalInfo',
          // $FlowFixMe mixed
          value: item.additionalInfo,
          multiline: true,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'origin',
          // $FlowFixMe mixed
          value: item.origin,
          disabled: false,
        },
        {
          type: 'DocumentItemLock',
          name: 'locks',
          // $FlowFixMe mixed
          value: item.locks,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'LocalizedFile',
          multiple: true,
          name: 'lockedImage',
          suffix: 'locked',
          // $FlowFixMe mixed
          value: item.lockedImage,
          accept: '.png,.jpg,.jpeg',
          // $FlowFixMe
          hidden: (state: State) => !state.locks || !state.locks.length,
        },
        {
          type: 'number',
          name: 'timeOnError',
          // $FlowFixMe mixed
          value: item.timeOnError,
          disabled: false,
          separatorBefore: true,
          // $FlowFixMe
          hidden: (state: State) => !state.locks || !state.locks.length,
        },
      ];

    case ItemTypes.Video:
      return [
        {
          type: 'LocalizedFile',
          multiple: true,
          name: 'video',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.video,
          disabled: false,
          separatorBefore: true,
          accept: '.mp4',
          sizeErrorLimit: fileSizeLimits.video,
        },
        {
          type: 'enum',
          name: 'videoArchiveItemId',
          // $FlowFixMe mixed
          value: item.videoArchiveItemId,
          values: Object.values(items)
            .filter((it) => !!it.id && it.type === ItemTypes.Archive)
            .map((it) => it.id),
          emptyPlaceholder: 'Archive pointée',
        },
        {
          type: 'boolean',
          name: 'closable',
          // $FlowFixMe mixed
          value: item.closable,
          separatorBefore: true,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'autoCloseOnFinish',
          // $FlowFixMe mixed
          value: item.autoCloseOnFinish,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'usePresentation',
          // $FlowFixMe mixed
          value: item.usePresentation,
          separatorBefore: true,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'useFullArchive',
          // $FlowFixMe mixed
          value: item.useFullArchive,
          disabled: false,
          hidden: (state: State) => !state.videoArchiveItemId,
        },
        {
          type: 'localizedString',
          name: 'title',
          // $FlowFixMe mixed
          value: item.title,
          multiline: true,
          hidden: (state: State) => !state.usePresentation,
          separatorBefore: false,
        },
        {
          type: 'localizedString',
          name: 'description',
          // $FlowFixMe mixed
          value: item.description,
          multiline: true,
          hidden: (state: State) => !state.usePresentation,
        },
        {
          type: 'LocalizedFile',
          multiple: true,
          name: 'cover',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.cover,
          hidden: (state: State) => !state.usePresentation,
          separatorBefore: false,
          accept: '.png,.jpg,.jpeg',
          sizeErrorLimit: fileSizeLimits.image,
        },
      ];

    case ItemTypes.Image360:
      return [
        {
          type: 'LocalizedFile',
          multiple: true,
          suffix: '360',
          name: 'image',
          // $FlowFixMe mixed
          value: item.images,
          disabled: false,
          separatorBefore: true,
          accept: '.jpg,.jpeg',
          sizeErrorLimit: fileSizeLimits.image360,
        },
        {
          type: 'boolean',
          name: 'enableTouchTracking',
          // $FlowFixMe mixed
          value: item.enableTouchTracking,
          separatorBefore: true,
          disabled: false,
        },
      ];
    case ItemTypes.Discussion || ItemTypes.DiscussionPOI:
      return [
        {
          type: 'link',
          name: t('general.edit'),
          value: Routes.SCENARIO_DISCUSSION_EDITION.replace(':scenarioId', scenarioId).replace(
            ':discussionId',
            // $FlowFixMe mixed
            item.id,
          ),
          // $FlowFixMe mixed
          disabled: !item.id,
        },
      ];
    case ItemTypes.Failure:
      return [
        {
          type: 'localizedString',
          name: 'message',
          // $FlowFixMe mixed
          value: item.message,
          multiline: true,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'boolean',
          name: 'canRetry',
          // $FlowFixMe mixed
          value: item.canRetry,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'boolean',
          name: 'canContinue',
          // $FlowFixMe mixed
          value: item.canContinue,
          disabled: false,
        },
      ];
    case ItemTypes.GameArea:
      return [
        {
          type: 'GameArea',
          name: 'area',
          // $FlowFixMe mixed
          value: item.area,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'title',
          // $FlowFixMe mixed
          value: item.title,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'message',
          // $FlowFixMe mixed
          value: item.message,
          multiline: true,
          disabled: false,
        },
      ];
    case ItemTypes.Openable:
      return [
        {
          type: 'localizedString',
          name: 'description',
          // $FlowFixMe mixed
          value: item.description,
          multiline: true,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'validateText',
          // $FlowFixMe mixed
          value: item.validateText,
          disabled: false,
        },
      ];

    case ItemTypes.POI:
      return [
        {
          type: 'latLngAlt',
          name: 'coordinate',
          // $FlowFixMe mixed
          value: item.coordinate,
          disabled: false,
          handlePolyline: (state: State, polyline) => {
            switch (state.patrolType) {
              case POIItemPatrolTypes.OneWay:
              case POIItemPatrolTypes.GoingsComings:
              case POIItemPatrolTypes.Cyclic:
              default: {
                const res = { polyline: [], coordinate: [] };
                if (!polyline) {
                  return;
                }
                res.coordinate = polyline[0];
                res.polyline = polyline.slice(1);
                return res;
              }
            }
          },
        },
        {
          type: 'number',
          name: 'distanceMinToTrigger',
          // $FlowFixMe mixed
          value: item.distanceMinToTrigger,
          suffixLabel: 'distanceMinToTriggerShort',
          disabled: false,
        },
        {
          type: 'enum',
          name: 'patrolType',
          // $FlowFixMe mixed
          value: item.patrolType,
          values: state.configuration.patrolTypes,
          disabled: false,
          separatorBefore: true,
          itemToTitle: (it) => t(`patrolTypes.${it}`),
          handlePolyline: (state: State, polyline) => {
            if (polyline.length > 2) {
              const first = polyline[0];
              const last = polyline[polyline.length - 1];
              if (first[0] === last[0] && first[1] === last[1]) {
                return POIItemPatrolTypes.Cyclic;
              } else if ([POIItemPatrolTypes.Cyclic, POIItemPatrolTypes.None].includes(state.patrolType)) {
                return POIItemPatrolTypes.OneWay;
              }
              return undefined;
            }
          },
        },
        {
          type: 'multipleLatLngAlt',
          name: 'patrolCoordinates',
          // $FlowFixMe mixed
          value: item.patrolCoordinates,
          disabled: false,
          // $FlowFixMe
          hidden: (state: State) => state.patrolType === POIItemPatrolTypes.None,
          handlePolyline: (state: State, polyline) => {
            switch (state.patrolType) {
              case POIItemPatrolTypes.OneWay:
              case POIItemPatrolTypes.GoingsComings:
              case POIItemPatrolTypes.Cyclic:
              default: {
                const res = { polyline: [], coordinate: [] };
                if (!polyline) {
                  return;
                }
                res.coordinate = polyline[0];
                const last = polyline[polyline.length - 1];
                if (last[0] === res.coordinate[0] && last[1] === res.coordinate[1]) {
                  res.polyline = polyline.slice(1, polyline.length - 1);
                } else {
                  res.polyline = polyline.slice(1);
                }
                return res;
              }
            }
          },
        },
        {
          type: 'jsonArray',
          name: 'patrolSpeeds',
          // $FlowFixMe mixed
          value: item.patrolSpeeds,
          disabled: false,
          isNumber: true,
          // $FlowFixMe
          hidden: (state: State) => state.patrolType === POIItemPatrolTypes.None,
        },
        {
          type: 'checkboxLabel',
          name: 'display',
        },
        {
          type: 'boolean',
          name: 'autoPauseOnEachReach',
          // $FlowFixMe mixed
          value: item.autoPauseOnEachReach,
          disabled: false,
          // $FlowFixMe
          hidden: (state: State) => state.patrolType === POIItemPatrolTypes.None,
        },
        {
          type: 'boolean',
          name: 'visibleOnMap',
          // $FlowFixMe mixed
          value: item.visibleOnMap,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'boolean',
          name: 'autocompleteAfterLaunch',
          // $FlowFixMe mixed
          value: item.autocompleteAfterLaunch,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'launchOnFirstReach',
          // $FlowFixMe mixed
          value: item.launchOnFirstReach,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'visibleAfterCompleted',
          // $FlowFixMe mixed
          value: item.visibleAfterCompleted,
          disabled: false,
        },
        {
          type: 'boolean',
          name: 'disableNotification',
          // $FlowFixMe mixed
          value: item.disableNotification,
          disabled: false,
        },
        {
          type: 'poiTypes',
          name: 'poiTypes',
          // $FlowFixMe mixed
          value: item.poiTypes,
          values: Object.values(POIItemPOITypes),
          disabled: false,
        },
      ];
    case ItemTypes.SecondaryMission:
      return [];

    case ItemTypes.Unlockable:
      return [
        {
          type: 'localizedString',
          name: 'name',
          // $FlowFixMe mixed
          value: item.name,
          multiline: true,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'string',
          name: 'unlockableId',
          // $FlowFixMe mixed
          value: item.unlockableId,
          disabled: false,
        },
      ];
    case ItemTypes.Start:
      return [];
    case ItemTypes.Success:
      return [];
    case ItemTypes.Timer:
      return [
        {
          type: 'number',
          name: 'duration',
          // $FlowFixMe mixed
          value: item.duration,
          disabled: false,
          separatorBefore: true,
        },
      ];
    case ItemTypes.TimeTravel:
      const musicInputs = isConfirmedEditor
        ? [
            {
              type: 'boolean',
              name: 'stillPlayMusic',
              // $FlowFixMe mixed
              value: item.stillPlayMusic,
              disabled: false,
            },
            {
              type: 'boolean',
              name: 'reduceMusicVolume',
              // $FlowFixMe mixed
              value: item.reduceMusicVolume,
              disabled: false,
            },
          ]
        : [];
      return [
        {
          type: 'number',
          name: 'year',
          // $FlowFixMe mixed
          value: item.year,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'checkboxLabel',
          name: 'musicEffects',
        },
        ...musicInputs,
        {
          type: 'boolean',
          name: 'disableTimeTravelSound',
          // $FlowFixMe mixed
          value: item.disableTimeTravelSound,
          disabled: false,
        },
        {
          type: 'spacer',
        },
        {
          type: 'localizedString',
          name: 'yearText',
          // $FlowFixMe mixed
          value: item.yearText,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'transitionText',
          // $FlowFixMe mixed
          value: item.transitionText,
          disabled: false,
        },
        {
          type: 'enum',
          name: 'mapStyleName',
          // $FlowFixMe mixed
          value: item.mapStyleName,
          values: state.configuration.mapStyles,
          disabled: false,
        },
        {
          type: 'enum',
          name: 'fontStyleName',
          // $FlowFixMe mixed
          value: item.fontStyleName,
          values: state.configuration.fontStyles,
          disabled: false,
        },
      ];
    case ItemTypes.Tool:
      return [
        {
          type: 'LocalizedFiles',
          multiple: true,
          name: 'images',
          suffix: undefined,
          // $FlowFixMe mixed
          value: item.images,
          disabled: false,
          separatorBefore: true,
          accept: '.png,.jpg,.jpeg',
        },
        {
          type: 'localizedString',
          name: 'name',
          // $FlowFixMe mixed
          value: item.name,
          disabled: false,
          separatorBefore: true,
        },
        {
          type: 'localizedString',
          name: 'additionalInfo',
          // $FlowFixMe mixed
          value: item.additionalInfo,
          multiline: true,
          disabled: false,
        },
        {
          type: 'localizedString',
          name: 'origin',
          // $FlowFixMe mixed
          value: item.origin,
          disabled: false,
        },
        {
          type: 'string',
          name: 'technicalName',
          // $FlowFixMe mixed
          value: item.technicalName,
          disabled: false,
        },
      ];
    case ItemTypes.BackgroundMusic:
      console.log(item.musicFile);
      return [
        {
          type: 'LocalizedFile',
          multiple: true,
          suffix: 'music',
          name: 'musicFile',
          value: item.musicFile,
          accept: '.mp3',
          disabled: false,
          sizeErrorLimit: fileSizeLimits.music,
        },
        {
          type: 'number',
          name: 'volume',
          // $FlowFixMe mixed
          value: item.volume,
          disabled: false,
        },
      ];
    case ItemTypes.BackgroundMusicControls:
      return [
        {
          type: 'boolean',
          name: 'changeVolume',
          value: item.changeVolume,
          separatorBefore: true,
          disabled: false,
        },
        {
          type: 'number',
          name: 'volume',
          value: item.volume,
          disabled: false,
          hidden: (state) => !state.changeVolume,
        },
        {
          type: 'enum',
          name: 'controlAction',
          // $FlowFixMe mixed
          value: item.controlAction,
          values: Object.keys(controlActions),
          itemToTitle: (id) => t(`screens.scenarioEdition.baseItemEdition.controlActions.${id}`),
          disabled: false,
        },
      ];
    case ItemTypes.SoundEffect:
      return [
        {
          type: 'LocalizedFile',
          multiple: true,
          suffix: 'music',
          name: 'soundFile',
          value: item.soundFile,
          accept: '.mp3',
          disabled: false,
          sizeErrorLimit: fileSizeLimits.soundEffect,
        },
        {
          type: 'number',
          name: 'volume',
          // $FlowFixMe mixed
          value: item.volume,
          disabled: false,
        },
      ];
    default:
      return [];
  }
};

const mapStateToProps = (state, ownProps) => {
  const { itemId, nodeId, t } = ownProps;
  const { items } = state.scenario;
  const { fileSizeLimits } = state.configuration;
  let itemRedux;
  let description = [];
  if (!itemId) {
    itemRedux = items.__detachedNodes.items.find((it) => it.nodeId === nodeId);
    if (!itemRedux) {
      // $FlowFixMe Object.values
      itemRedux = Object.values(items).find((it) => it.nodeId === nodeId);
    }
  } else {
    itemRedux = items[itemId];
  }
  const scenarioId = state.scenario.header.id;
  const title = itemRedux && t('screens.scenarioEdition.baseItemEdition.sectionTitlePrefix') + itemRedux.type;
  let idSuffix = '';
  const isConfirmedEditor =
    ownProps.validClaims.includes(Claims.ConfirmedEditor) ||
    ownProps.validClaims.includes(Claims.Admin) ||
    ownProps.validClaims.includes(Claims.Moderator);
  if (itemRedux) {
    description = fieldsDescriptionFromType(
      itemRedux.type,
      itemRedux,
      items,
      state,
      scenarioId,
      t,
      fileSizeLimits,
      isConfirmedEditor,
    );
    switch (itemRedux.type) {
      case ItemTypes.Anecdote:
        idSuffix = '_anecdote';
        break;
      case ItemTypes.AnecdotePOI:
        idSuffix = '_anecdotepoi';
        break;
      case ItemTypes.Archive:
        idSuffix = '_archive';
        break;
      case ItemTypes.Checkpoint:
        idSuffix = '_checkpt';
        break;
      case ItemTypes.Comment:
        idSuffix = '_comment';
        break;
      case ItemTypes.Custom:
        idSuffix = '_custom';
        break;
      case ItemTypes.Discussion:
        idSuffix = '_disc';
        break;
      case ItemTypes.DiscussionPOI:
        idSuffix = '_discpoi';
        break;
      case ItemTypes.Document:
        idSuffix = '_doc';
        break;
      case ItemTypes.DocumentPOI:
        idSuffix = '_docpoi';
        break;
      case ItemTypes.Failure:
        idSuffix = '_failure';
        break;
      case ItemTypes.Openable:
        idSuffix = '_openable';
        break;
      case ItemTypes.GameArea:
        idSuffix = '_zone';
        break;
      case ItemTypes.Image360:
        idSuffix = '_image360';
        break;
      case ItemTypes.Video:
        idSuffix = '_video';
        break;
      case ItemTypes.Unlockable:
        idSuffix = '_unlockable';
        break;
      case ItemTypes.POI:
        idSuffix = '_poi';
        break;
      case ItemTypes.SecondaryMission:
        idSuffix = '_secondary';
        break;
      case ItemTypes.TimeTravel:
        idSuffix = '_tt';
        break;
      case ItemTypes.Timer:
        idSuffix = '_timer';
        break;
      case ItemTypes.Tool:
        idSuffix = '_tool';
        break;
      case ItemTypes.BackgroundMusic:
        idSuffix = '_bg_music';
        break;
      case ItemTypes.BackgroundMusicControls:
        idSuffix = '_bg_music_controls';
        break;
      case ItemTypes.SoundEffect:
        idSuffix = '_sound_effect';
        break;
      default:
        break;
    }
  }
  // $FlowFixMe Object.values
  const itemIds = Object.values(items).map((it) => it.id);
  const itemStates = itemRedux && itemRedux.type && state.configuration.screenplay[itemRedux.type];
  const itemColor = itemTypeColorator(itemRedux);
  return {
    isConfirmedEditor,
    idSuffix,
    scenarioId: state.scenario.header.id,
    item: itemRedux,
    restrictedFields: itemRedux && itemRedux.type && state.configuration.restrictedFields[itemRedux.type],
    validityCheck: itemId && state.scenario.validity[itemId],
    title,
    locale: state.preferences.editionLocale,
    fieldsDescription: description,
    existingItemIds: itemIds,
    itemStates,
    itemUnlockedValues: itemRedux && itemRedux.unlockedValues && Object.values(itemRedux.unlockedValues),
    hideClues: !itemRedux || itemRedux.type !== ItemTypes.Document,
    idRequiredFieldsDescription: [],
    itemColor,
  };
};

const mapDispatchToProps = {
  updateItem: ItemsServiceHelper.updateItem,
  createItem: ItemsServiceHelper.createItem,
  deleteItem: ItemsServiceHelper.removeItem,
  addNotif: EventsServiceHelper.addNotif,
};

export default withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.ConfirmedEditor, Claims.Admin])(
  compose(
    withFirebase,
    withTranslation(['default', 'helpStrings']),
    connect(mapStateToProps, mapDispatchToProps),
  )(DefaultItemInput),
);

const mapStateToAMSProps = (state, ownProps) => {
  const { itemId, t } = ownProps;
  const items = state.ams;
  let description = [];
  let idRequiredFieldsDescription = [];
  let itemRedux = items[itemId];
  const title = itemRedux && t('screens.scenarioEdition.baseItemEdition.sectionTitlePrefix') + itemRedux.type;
  let idSuffix = '';
  let isCreate = false;
  if (!itemRedux && !!ownProps.marker) {
    itemRedux = new AMSItem({ type: ItemTypes.AMS });
    isCreate = true;
  }
  const cityIds = state.configuration.availableCities.map((city) => city.id);
  if (itemRedux) {
    description = [
      {
        type: 'latLngAlt',
        name: 'coordinate',
        value: itemRedux.coordinate,
        disabled: false,
        separatorBefore: true,
      },
      {
        type: 'number',
        name: 'height',
        value: itemRedux.height,
        disabled: false,
      },
      {
        type: 'number',
        name: 'startYear',
        value: itemRedux.startYear,
        disabled: false,
        separatorBefore: true,
      },
      {
        type: 'localizedString',
        name: 'name',
        value: itemRedux.name,
        disabled: false,
      },
      {
        type: 'localizedString',
        name: 'subtitle',
        value: itemRedux.subtitle,
        disabled: false,
      },
      {
        type: 'localizedString',
        name: 'contentText',
        value: itemRedux.contentText,
        disabled: false,
        multiline: true,
      },
      {
        type: 'localizedString',
        name: 'annotations',
        value: itemRedux.annotations,
        disabled: false,
      },
    ];
    idRequiredFieldsDescription = [
      {
        type: 'enum',
        name: 'cityId',
        value: itemRedux.cityId,
        values: cityIds,
        disabled: !!itemRedux.id,
        emptyPlaceholder: 'Out of city',
      },
    ];
    idSuffix = '_ams';
  }
  // $FlowFixMe Object.values
  const itemIds = Object.values(items).map((it) => it.id);
  return {
    idSuffix,
    idPrefix: (viewState) => (viewState.cityId ? `${viewState.cityId}_` : '_'),
    item: itemRedux,
    isCreate,
    title,
    locale: state.preferences.editionLocale,
    fieldsDescription: description,
    idRequiredFieldsDescription,
    existingItemIds: itemIds,
    hideBaseItemFields: true,
    hideClues: true,
    itemsStates: [],
    idKey: 'id',
    isAms: true,
  };
};

const mapDispatchToAMSProps = {
  updateItem: AmsServiceHelper.updateAms,
  createItem: AmsServiceHelper.createAms,
  deleteItem: AmsServiceHelper.removeAms,
  addNotif: EventsServiceHelper.addNotif,
};

const AMSInput = withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.ConfirmedEditor, Claims.Admin])(
  compose(
    withFirebase,
    withTranslation(['default', 'helpStrings']),
    connect(mapStateToAMSProps, mapDispatchToAMSProps),
  )(DefaultItemInput),
);

export { AMSInput };
