/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Loader } from 'src/pages/components';
import { withTranslation } from 'react-i18next';

import { Constants } from 'src/assets';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import NotificationsView from './components/notifications/NotificationsView';
import Firebase, { withFirebase } from '../services/Firebase';
import * as ROUTES from '../constants/routes';
import { UserServiceHelper } from '../store/user';
import { InputBoolean } from './components';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../assets/styles/app.scss';

export type LoginScreenProps = {
  login: UserServiceHelper.loginType,
  sendForgotPassword: UserServiceHelper.socialLoginAsyncType,
  signIn: UserServiceHelper.signUpAsyncType,
  lastEmail: string,
  firebase: Firebase,
  history: any,
  t: (key: string) => string,
};

type State = {
  cguAgreed: boolean,
  confidentialityAgreed: false,
  email: string,
  password: string,
  passwordConfirm: string,
  error?: string,
  isValid: boolean,
  isLoading: boolean,
  isEmailValid: boolean,
  isSignIn: boolean,
  hasChanges: boolean,
};

class LoginScreen extends React.PureComponent<LoginScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    email: '',
    password: '',
    passwordConfirm: '',
    error: undefined,
    isValid: false,
    hasChanges: false,
    cguAgreed: false,
    confidentialityAgreed: false,
    isSignIn: false,
    isEmailValid: false,
    isLoading: false,
  };

  componentDidMount() {
    try {
      this.initData(this.props);
    } catch (error) {
      console.debug(error);
    }
  }

  componentDidUpdate(oldProps: LoginScreenProps) {
    try {
      if (oldProps.lastEmail !== this.props.lastEmail) {
        this.initData(this.props);
      }
    } catch (error) {
      console.debug(error);
    }
  }

  initData = (props: LoginScreenProps) => {
    const { lastEmail } = props;
    this.setState({
      email: lastEmail || '',
    });
    this.updateValidity({
      email: lastEmail || '',
    });
  };

  login = async (event) => {
    const { email, password } = this.state;
    const { login, firebase } = this.props;
    this.setState({ isLoading: true });
    if (login) {
      try {
        const user = await login(email, password, firebase);
        this.onLoggedIn(user);
      } catch (error) {
        this.setState({ error: error.message });
        this.setState({ isLoading: false });
      }
    }
    event.preventDefault();
  };

  onLoggedIn = (user: any) => {
    this.setState({ password: '', error: undefined, isSignIn: false });
    this.props.history.push(ROUTES.SCENARIO_DASHBOARD);
    this.setState({ isLoading: false });
  };

  signIn = async (event) => {
    if (!this.state.isSignIn) {
      return this.showSignIn();
    }
    const { email, password, cguAgreed, confidentialityAgreed } = this.state;
    const { signIn, firebase } = this.props;
    if (!confidentialityAgreed) {
      await this.props.addNotif(NotificationTypes.ERROR, 'E_PRIVACY_REQUIRED');
    } else if (!cguAgreed) {
      await this.props.addNotif(NotificationTypes.ERROR, 'E_CGU_REQUIRED');
    } else if (signIn) {
      this.setState({ isLoading: true });
      try {
        const user = await signIn(email, password, cguAgreed, confidentialityAgreed, firebase);
        this.onLoggedIn(user);
      } catch (error) {
        console.error(error);
        this.setState({ error: error.message });
        this.setState({ isLoading: false });
      }
    }
    event.preventDefault();
  };

  forgotPassword = async () => {
    const { email } = this.state;
    const { firebase } = this.props;
    this.setState({ isLoading: true });
    await this.props.sendForgotPassword(email, firebase);
    this.setState({ isLoading: false });
  };

  updateValidity = (state) => {
    const stateToUse = state || this.state;
    const isValid =
      !!stateToUse.email && stateToUse.email.length > 0 && !!stateToUse.password && stateToUse.password.length > 0;
    const isEmailValid = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
      stateToUse.email,
    );
    this.setState({
      isValid,
      isEmailValid,
    });
  };

  handleChange = (event: any) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  showSignIn = () => {
    this.setState({ isSignIn: true });
  };

  hideSignIn = () => {
    this.setState({ isSignIn: false });
  };

  onFacebookLogin = async () => {
    this.setState({ isLoading: true });
    try {
      const user = await this.props.loginWithFacebook(this.props.firebase);
      this.onLoggedIn(user);
    } catch (error) {
      this.setState({ error: error.message });
    }
    this.setState({ isLoading: false });
  };

  onGoogleLogin = async () => {
    this.setState({ isLoading: true });
    try {
      const user = await this.props.loginWithGoogle(this.props.firebase);
      this.onLoggedIn(user);
    } catch (error) {
      this.setState({ error: error.message });
    }
    this.setState({ isLoading: false });
  };

  onAppleLogin = async () => {
    this.setState({ isLoading: true });
    try {
      const user = await this.props.loginWithApple(this.props.firebase);
      this.onLoggedIn(user);
    } catch (error) {
      this.setState({ error: error.message });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const {
      isLoading,
      isValid,
      email,
      password,
      passwordConfirm,
      error,
      isSignIn,
      cguAgreed,
      confidentialityAgreed,
      isEmailValid,
    } = this.state;
    const { t } = this.props;
    return (
      <div id="login">
        <div className="popup col-md-4 col-12 bg-grey">
          <img src={require('../assets/images/atlantide_logo_c.svg')} id="logo" alt="logo" />
          <h3 id="title" className="titleFont">
            {Constants.editorName}
          </h3>

          {!!error && <p className="error text-center">{t(`notifications.${error}`)}</p>}

          <form>
            <div className="form-group">
              <label htmlFor="email">{t('screens.login.email')}</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                placeholder={t('screens.login.emailPlaceholder')}
                onChange={this.handleChange}
                value={email}
              />
              <small id="emailHelp" className="form-text text-muted">
                {t('screens.login.emailHelp')}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="password">{t('screens.login.password')}</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder={t('screens.login.passwordPlaceholder')}
                onChange={this.handleChange}
                value={password}
              />
              {isSignIn && password.length > 0 && password.length < 8 && (
                <span className="error">{t('screens.login.passwordTooShort')}</span>
              )}
            </div>
            {isSignIn && (
              <div className="form-group">
                <label htmlFor="passwordConfirm">{t('screens.login.passwordConfirm')}</label>
                <input
                  type="password"
                  className="form-control"
                  id="passwordConfirm"
                  placeholder={t('screens.login.passwordConfirmPlaceholder')}
                  onChange={this.handleChange}
                  value={passwordConfirm}
                />
                {isSignIn && passwordConfirm && passwordConfirm !== password && (
                  <span className="error">{t('screens.login.passwordNoMatch')}</span>
                )}
              </div>
            )}
            {!isSignIn && (
              <React.Fragment>
                <button type="button" className="btn btn-light" disabled={!email.length} onClick={this.forgotPassword}>
                  {t('screens.login.forgotPassword')}
                </button>
                <button type="button" className="btn btn-primary" disabled={!isValid} onClick={this.login}>
                  {t('screens.login.login')}
                </button>
              </React.Fragment>
            )}
            {isSignIn && (
              <React.Fragment>
                <InputBoolean
                  fieldName="cguAgreed"
                  value={cguAgreed}
                  label={
                    <a href={this.props.firebase.tosUrl} target="_blank" rel="noreferrer">
                      {t('screens.login.agreeCgu')}
                    </a>
                  }
                  handleChange={this.handleChange}
                />
                <InputBoolean
                  fieldName="confidentialityAgreed"
                  value={confidentialityAgreed}
                  label={
                    <a href={this.props.firebase.privacyUrl} target="_blank" rel="noreferrer">
                      {t('screens.login.agreeConfidenciality')}
                    </a>
                  }
                  handleChange={this.handleChange}
                />
              </React.Fragment>
            )}
          </form>
        </div>
        <NotificationsView />
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lastEmail: state.user.lastEmail,
});

const mapDispatchToProps = {
  login: UserServiceHelper.loginAsync,
  signIn: UserServiceHelper.signUpAsync,
  sendForgotPassword: UserServiceHelper.sendForgotPassword,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withFirebase,
  withTranslation('default'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(LoginScreen);
